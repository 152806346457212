// Theme
import {
  BsInputCursorText,
  BsStarHalf,
  BsCalendarDate,
  BsListOl,
  BsTextarea,
} from "react-icons/bs";
import { ImSortNumbericDesc } from "react-icons/im";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { VscListSelection } from "react-icons/vsc";

export const THEME_SET = "THEME_SET";
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};
// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST";
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS";
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const CHANGE_MFA = "CHANGE_MFA";
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST";
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS";
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE";
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE";

export const CHANGE_SELFIE_USER = "CHANGE_SELFIE_USER";
export const CHANGE_EMPRESA = "CHANGE_EMPRESA";
export const CHANGE_EMPRESA_ALTER = "CHANGE_EMPRESA_ALTER";
export const CHANGE_PREFERENCES_DASHBOARD = "CHANGE_PREFERENCES_DASHBOARD";
export const CHANGE_LAST_ACCESS = "CHANGE_LAST_ACCESS";
export const CHANGE_NOTIFICACOES = "CHANGE_NOTIFICACOES";
export const TOKEN_MAPBOX =
  "pk.eyJ1IjoicHNhaW5mbyIsImEiOiJja2xoejdnOWswNHdtMndxcjZzZDBjNmwyIn0.Ilns-aRd6SG7J1wtnwbKjQ";
export const LATITUDE_DEFAULT = -15.894055850789314;
export const LONGITUDE_DEFAULT = -52.257905584611386;

export const langHighcharts = {
  decimalPoint: ",",
  thousandsSep: ".",
  contextButtonTitle: "Menu do Gráfico",
  downloadCSV: "Download CSV",
  downloadJPEG: "Download JPEG",
  downloadPDF: "Download PDF",
  downloadPNG: "Download PNG",
  downloadSVG: "Download SVG",
  downloadXLS: "Download XLS",
  drillUpText: "Voltar Para {series.name}",
  loading: "Carregando...",
  months: [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ],
  navigation: {
    popup: {
      addButton: "Adicionar",
      background: "Fundo",
    },
  },
  tooltip: {
    yDecimals: 2,
  },
  noData: "Nenhum dado para exibir",
  openInCloud: "Abrir no Highcharts Cloud",
  printChart: "Imprimir Gráfico",
  viewFullscreen: "Visualizar em tela cheia",
  resetZoom: "Resetar zoom",
  resetZoomTitle: "Resetar zoom nível 1:1",
  shortMonths: [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ],
  viewData: "Visualizar dados",
  weekdays: [
    "Domingo",
    "Segunda-Feira",
    "Terça-Feira",
    "Quarta-Feira",
    "Quinta-Feira",
    "Sexta-Feira",
    "Sábado",
  ],
};

export const filtros = [
  {
    name: "Falta",
    value: "FALTA",
  },
  {
    name: "Horas Faltantes",
    value: "HORAS_FALTANTES",
  },
  {
    name: "Hora extra 1",
    value: "HORA_EXTRA_1",
  },
  {
    name: "Hora extra 2",
    value: "HORA_EXTRA_2",
  },
  {
    name: "Hora extra 3",
    value: "HORA_EXTRA_3",
  },
  {
    name: "Adicional Noturno",
    value: "ADICIONAL_NOTURNO",
  },
  {
    name: "Pontos Inconsistentes",
    value: "INCONSISTENTE",
  },
];

export const filtrosAdNoturno = [
  {
    name: "Falta",
    value: "FALTA",
  },
  {
    name: "Horas Faltantes",
    value: "HORAS_FALTANTES",
  },
  {
    name: "Hora extra 1",
    value: "HORA_EXTRA_1",
  },
  {
    name: "Hora extra 2",
    value: "HORA_EXTRA_2",
  },
  {
    name: "Hora extra 3",
    value: "HORA_EXTRA_3",
  },
  {
    name: "Adicional Noturno",
    value: "ADICIONAL_NOTURNO",
  },
  {
    name: "Hora extra noturna 1",
    value: "HORA_EXTRA_NOTURNA_1",
  },
  {
    name: "Hora extra noturna 2",
    value: "HORA_EXTRA_NOTURNA_2",
  },
  {
    name: "Pontos Inconsistentes",
    value: "INCONSISTENTE",
  },
];

export const TIPO_CONTA = [
  {
    cod: "CORRENTE",
    name: "Conta Corrente",
  },
  {
    cod: "POUPANCA",
    name: "Conta Poupança",
  },
  {
    cod: "CONTA_SALARIO",
    name: "Conta Salário",
  },
  {
    cod: "CONTA_CORRENTE_CONJUNTA",
    name: "Conta Corrente Conjunta",
  },
  {
    cod: "POUPANCA_CONJUNTA",
    name: "Conta Poupança Conjunta",
  },
  {
    cod: "CONTA_INVESTIMENTO",
    name: "Conta de Investimento",
  },
];

export const RACA = [
  {
    cod: "NENHUM",
    name: "Nenhum",
  },
  {
    cod: "INDIGENA",
    name: "Indígena",
  },
  {
    cod: "BRANCA",
    name: "Branca",
  },
  {
    cod: "PRETA",
    name: "Preta",
  },
  {
    cod: "AMARELA",
    name: "Amarela",
  },
  {
    cod: "PARDA",
    name: "Parda",
  },
];

export const ESTADO_CIVIL = [
  {
    cod: "CASADO",
    name: "Casado",
  },
  {
    cod: "SOLTEIRO",
    name: "Solteiro",
  },
  {
    cod: "SEPARADO",
    name: "Separado",
  },
  {
    cod: "VIUVO",
    name: "Viúvo",
  },
  {
    cod: "DIVORCIADO",
    name: "Divorciado",
  },
  {
    cod: "UNIAO_ESTAVEL",
    name: "União Estável",
  },
];

export const SEXO = [
  {
    cod: "MASCULINO",
    name: "Masculino",
  },
  {
    cod: "FEMININO",
    name: "Feminino",
  },
  {
    cod: "NAO_BINARIO",
    name: "Não-Binário",
  },
];

export const ESCOLARIDADE = [
  "Analfabeto, inclusive o que, embora tenha recebido instrução, não se alfabetizou",
  "Até o 5º ano incompleto do Ensino Fundamental (antiga 4ª série) ou que se tenha alfabetizado sem ter frequentado escola regular",
  "5º ano completo do Ensino Fundamental",
  "Do 6º ao 9º ano do Ensino Fundamental incompleto (antiga 5ª à 8ª série)",
  "Ensino Fundamental completo",
  "Ensino Médio incompleto",
  "Ensino Médio completo",
  "Educação Superior incompleta",
  "Educação Superior completa",
  "Mestrado completo",
  "Doutorado completo",
  "Pós Graduação completa",
  "Técnico completo",
  "Técnologo completo",
  "Pós Graduação incompleta",
  "Mestrado incompleto",
  "Doutorado incompleto",
  "Técnico incompleto",
  "Técnologo incompleto",
];

export const RELACAO = [
  "Cônjuge",
  "Companheiro com filhos e/ou união estável",
  "Filho ou enteado até 21 anos",
  "Filho ou enteado universitário ou escola técnica",
  "Irmão(ã), neto(a) ou bisneto(a) com guarda",
  "Irmão(ã), neto(a) ou bisneto(a) com guarda universitário ou em escola ténica",
  "Pais, avós e bisavós",
  "Menor pobre, até 21 anos",
  "Incapaz",
  "Companheiro sem filhos e/ou união estável",
  "Agregado/Outros",
  "Ex cônjuge que receba pensão de alimentos",
  "Filhos maiores de 24 anos",
];

export const BANCO = [
  {
    cod: "BANCO_GERADOR",
    name: "BANCO GERADOR S.A.",
  },
  {
    cod: "BANCO_RODOBENS",
    name: "BANCO RODOBENS S.A",
  },
  {
    cod: "CC_UNICRED_NORTE_PARANA",
    name: "CC UNICRED NORTE DO PARANA",
  },
  {
    cod: "BANCO_RANDON",
    name: "BANCO RANDON S.A.",
  },
  {
    cod: "UNICRED_CENTRAL_SANTA_CATARINA",
    name: "UNICRED CENTRAL SANTA CATARINA",
  },
  {
    cod: "BRICKELL",
    name: "BRICKELL S A CREDITO, FINANCIA",
  },
  {
    cod: "COOPERATIVA_DE_CREDITO_RURAL",
    name: "COOPERATIVA DE CREDITO RURAL D",
  },
  {
    cod: "BANCO_DE_LA_PROVINCIA_DE_BUENO",
    name: "BANCO DE LA PROVINCIA DE BUENO",
  },
  {
    cod: "BANCO_DO_BRASIL",
    name: "BANCO DO BRASIL S.A.",
  },
  {
    cod: "BANCO_CENTRAL_DO_BRASIL",
    name: "BANCO CENTRAL DO BRASIL",
  },
  {
    cod: "BANCO_DA_AMAZONIA",
    name: "BANCO DA AMAZONIA S.A.",
  },
  {
    cod: "BANCO_NORDESTE_DO_BRASIL",
    name: "BANCO DO NORDESTE DO BRASIL S.",
  },
  {
    cod: "BANCO_SANTANDER_MARIDIONAL",
    name: "BANCO SANTANDER MERIDIONAL S.A",
  },
  {
    cod: "BANESTES",
    name: "BANESTES S.A BANCO DO ESTADO D",
  },
  {
    cod: "BANCO_DE_PERNANBUCO",
    name: "BANCO DE PERNAMBUCO S.A.-BANDE",
  },
  {
    cod: "BANCO_ALFA",
    name: "BANCO ALFA S/A",
  },
  {
    cod: "BANDO_DO_ESTADO_DE_SANTA_CATARINA",
    name: "BANCO DO ESTADO DE SANTA CATAR",
  },
  {
    cod: "BANCO_BANERJ",
    name: "BANCO BANERJ S.A.",
  },
  {
    cod: "BANCO_SANTANDER_BRASIL",
    name: "BANCO SANTANDER BRASIL S.A.",
  },
  {
    cod: "BANCO_BEC",
    name: "BANCO BEC S.A.",
  },
  {
    cod: "BANCO_BEM",
    name: "BANCO BEM S.A.",
  },
  {
    cod: "BANCO_DO_ESTADO_DO_PARA",
    name: "BANCO DO ESTADO DO PARA S.A.",
  },
  {
    cod: "BANCO_DO_ESTADO_DO_PIAUI",
    name: "BANCO DO ESTADO DO PIAUI S.A.-",
  },
  {
    cod: "BANCO_CARGILL",
    name: "BANCO CARGILL S.A",
  },
  {
    cod: "BANCO_DO_ESTADO_DO_RIO_GRANDE",
    name: "BANCO DO ESTADO DO RIO GRANDE",
  },
  {
    cod: "BANCO_BVA_SA",
    name: "BANCO BVA SA",
  },
  {
    cod: "BANCO_OPPORTUNITY",
    name: "BANCO OPPORTUNITY S.A.",
  },
  {
    cod: "BANCO_DO_ESTADO_DE_SERGIPE",
    name: "BANCO DO ESTADO DE SERGIPE S.A",
  },
  {
    cod: "HIPERCARD_BANCO_MULTIPLO",
    name: "HIPERCARD BANCO MULTIPLO S.A",
  },
  {
    cod: "BANCO_IBI_S_A",
    name: "BANCO IBI S.A - BANCO MULTIPLO",
  },
  {
    cod: "LEMON_BANK_BANCO_MULTIPLO",
    name: "LEMON BANK BANCO MULTIPLO S.A",
  },
  {
    cod: "BANCO_MORGAN_STANLEY_DEAN_WITT",
    name: "BANCO MORGAN STANLEY DEAN WITT",
  },
  {
    cod: "BPN_BRASIL_BANCO_MULTIPLO",
    name: "BPN BRASIL BANCO MULTIPLO S.A",
  },
  {
    cod: "BRB_CREDITO_FINANC_E_INVEST",
    name: "BRB CREDITO FINANC E INVEST SA",
  },
  {
    cod: "BANCO_RURAL_MAIS",
    name: "BANCO RURAL MAIS S.A",
  },
  {
    cod: "BB_BANCO_POPULAR_DO_BRASIL",
    name: "BB BANCO POPULAR DO BRASIL",
  },
  {
    cod: "BANCO_CR2",
    name: "BANCO CR2 S.A",
  },
  {
    cod: "BANCO_KDB_DO_BRASIL",
    name: "BANCO KDB DO BRASIL S.A",
  },
  {
    cod: "BANCO_BM_F_DE_SERV_DE_LIQUIDA",
    name: "BANCO BM&F DE SERV. DE LIQUIDA",
  },
  {
    cod: "CAIXA_ECONOMICA_FEDERAL",
    name: "CAIXA ECONOMICA FEDERAL",
  },
  {
    cod: "BANCO_BBM",
    name: "BANCO BBM S.A",
  },
  {
    cod: "BANCO_UNICO",
    name: "BANCO UNICO S.A.",
  },
  {
    cod: "BANCO_NOSSA_CAIXA",
    name: "BANCO NOSSA CAIXA S.A",
  },
  {
    cod: "BANCO_FINASA",
    name: "BANCO FINASA S.A.",
  },
  {
    cod: "BANCO_ITAU_BBA",
    name: "BANCO ITAU BBA S.A",
  },
  {
    cod: "AMERICAN_EXPRESS_BANK",
    name: "AMERICAN EXPRESS BANK(BRASIL)",
  },
  {
    cod: "BANCO_PACTUAL",
    name: "BANCO PACTUAL S.A.",
  },
  {
    cod: "DRESDNER_BANK_LATEINAMERIKA",
    name: "DRESDNER BANK LATEINAMERIKA AK",
  },
  {
    cod: "BANCO_ORIGINAL",
    name: "BANCO ORIGINAL",
  },
  {
    cod: "BANCO_ARBI",
    name: "BANCO ARBI S.A.",
  },
  {
    cod: "BANCO_DIBENS",
    name: "BANCO DIBENS S.A.",
  },
  {
    cod: "BANCO_COMERCIAL_E_DE_INVESTIME",
    name: "BANCO COMERCIAL E DE INVESTIME",
  },
  {
    cod: "BANCO_JOHN_DEERE",
    name: "BANCO JOHN DEERE S.A.",
  },
  {
    cod: "BANCO_BONSUCESSO",
    name: "BANCO BONSUCESSO S.A.",
  },
  {
    cod: "BANCO_CALYON_BRASIL",
    name: "BANCO CALYON BRASIL S/A",
  },
  {
    cod: "BANCO_FIBRA",
    name: "BANCO FIBRA S.A.",
  },
  {
    cod: "BANCO_BRASCAN",
    name: "BANCO BRASCAN S.A.",
  },
  {
    cod: "BCSUL",
    name: "BCSUL",
  },
  {
    cod: "UNICARD_BANCO_MULTIPLO",
    name: "UNICARD BANCO MULTIPLO S.A",
  },
  {
    cod: "BANCO_GE_CAPITAL",
    name: "BANCO GE CAPITAL S.A",
  },
  {
    cod: "BANCO_CLASSICO",
    name: "BANCO CLASSICO S.A.",
  },
  {
    cod: "BANCO_MAXIMA",
    name: "BANCO MAXIMA S.A.",
  },
  {
    cod: "BANCO_ABC_BRASIL",
    name: "BANCO ABC BRASIL S.A.",
  },
  {
    cod: "BANCO_UBS",
    name: "BANCO UBS S.A",
  },
  {
    cod: "BANCO_BOA_VISTA_INTERATLANTICO",
    name: "BANCO BOA VISTA INTERATLANTICO",
  },
  {
    cod: "BANCO_INVESTCRED_UNIBANCO",
    name: "BANCO INVESTCRED UNIBANCO S.A",
  },
  {
    cod: "BANCO_SCHAHIN",
    name: "BANCO SCHAHIN S.A",
  },
  {
    cod: "BANCO_FININVEST",
    name: "BANCO FININVEST S.A.",
  },
  {
    cod: "PARANA_BANCO",
    name: "PARANA BANCO S.A.",
  },
  {
    cod: "BANCO_CACIQUE",
    name: "BANCO CACIQUE S.A.",
  },
  {
    cod: "BANCO_FATOR",
    name: "BANCO FATOR S.A.",
  },
  {
    cod: "BANCO_CEDULA",
    name: "BANCO CEDULA S.A.",
  },
  {
    cod: "BANCO_DE_LA_NACION_ARGENTINA",
    name: "BANCO DE LA NACION ARGENTINA",
  },
  {
    cod: "BANCO_BMG",
    name: "BANCO BMG S.A.",
  },
  {
    cod: "BANCO_INDUSTRIAL_COMERCIAL",
    name: "BANCO INDUSTRIAL E COMERCIAL S",
  },
  {
    cod: "BANCO_ITAU",
    name: "BANCO ITAU S.A.",
  },
  {
    cod: "BANCO_SUDAMERIS_DO_BRASIL",
    name: "BANCO SUDAMERIS DO BRASIL S.A",
  },
  {
    cod: "BANCO_SANTANDER",
    name: "BANCO SANTANDER S.A.",
  },
  {
    cod: "BANCO_SANTANDER_BRASIL_S_A",
    name: "BANCO SANTANDER BRASIL S.A.",
  },
  {
    cod: "BANCO_ABN_AMRO_REAL",
    name: "BANCO ABN AMRO REAL S.A.",
  },
  {
    cod: "BANCO_SOCIETE_GENERALE_BRASIL",
    name: "BANCO SOCIETE GENERALE BRASIL",
  },
  {
    cod: "BANCO_WESTLB_DO_BRASIL",
    name: "BANCO WESTLB DO BRASIL S.A.",
  },
  {
    cod: "BANCO_J_P_MORGAN",
    name: "BANCO J.P. MORGAN S.A.",
  },
  {
    cod: "BANCO_MERCANTIL_DO_BRASIL",
    name: "BANCO MERCANTIL DO BRASIL S.A.",
  },
  {
    cod: "BANCO_BMC",
    name: "BANCO BMC S.A.",
  },
  {
    cod: "HSBC_BANK_BRASIL",
    name: "HSBC BANK BRASIL S.A.-BANCO MU",
  },
  {
    cod: "UNIBANCO",
    name: "UNIBANCO - UNIAO DE BANCOS BRA",
  },
  {
    cod: "BANCO_CAPITAL",
    name: "BANCO CAPITAL S.A.",
  },
  {
    cod: "BANCO_SAFRA",
    name: "BANCO SAFRA S.A.",
  },
  {
    cod: "BANCO_RURAL",
    name: "BANCO RURAL S.A.",
  },
  {
    cod: "BANCO_DE_TOKYO_MITSUBISHI",
    name: "BANCO DE TOKYO MITSUBISHI UFJ",
  },
  {
    cod: "BANCO_SUMITOMO_MITSUI_BRASILEI",
    name: "BANCO SUMITOMO MITSUI BRASILEI",
  },
  {
    cod: "CITIBANK",
    name: "CITIBANK N.A.",
  },
  {
    cod: "BANKBOSTON_BANCO_MULTIPLO",
    name: "BANKBOSTON BANCO MULTIPLO S.A.",
  },
  {
    cod: "JPMORGAN_CHASE_BANK",
    name: "JPMORGAN CHASE BANK",
  },
  {
    cod: "ING_BANK",
    name: "ING BANK N.V.",
  },
  {
    cod: "BANCO_CREDIT_SUISSE",
    name: "BANCO CREDIT SUISSE (BRASIL) S",
  },
  {
    cod: "BANCO_INDUSTRIAL_DO_BRASIL",
    name: "BANCO INDUSTRIAL DO BRASIL S.",
  },
  {
    cod: "BANCO_VR",
    name: "BANCO VR S.A.",
  },
  {
    cod: "BANCO_GUANABARA",
    name: "BANCO GUANABARA S.A.",
  },
  {
    cod: "BANCO_PANAMERICANO",
    name: "BANCO PANAMERICANO S.A.",
  },
  {
    cod: "BANCO_RENDIMENTO",
    name: "BANCO RENDIMENTO S.A.",
  },
  {
    cod: "BANCO_TRIANGULO",
    name: "BANCO TRIANGULO S.A.",
  },
  {
    cod: "BANCO_PROSPER",
    name: "BANCO PROSPER S.A.",
  },
  {
    cod: "BANCO_ITAU_HOLDING_FINANCEIRA",
    name: "BANCO ITAU HOLDING FINANCEIRA",
  },
  {
    cod: "BANCO_A_J_RENNER",
    name: "BANCO A.J. RENNER S.A.",
  },
  {
    cod: "BANCO_VOTORANTIM",
    name: "BANCO VOTORANTIM S.A.",
  },
  {
    cod: "BANIF_BANCO_INTERNACIONAL",
    name: "BANIF-BANCO INTERNACIONAL DO F",
  },
  {
    cod: "BANCO_GERDAU",
    name: "BANCO GERDAU S.A.",
  },
  {
    cod: "BANCO_MORADA",
    name: "BANCO MORADA S.A.",
  },
  {
    cod: "BANCO_BGN",
    name: "BANCO BGN S.A.",
  },
  {
    cod: "BANCO_RIBEIRAO_PRETO",
    name: "BANCO RIBEIRAO PRETO S.A.",
  },
  {
    cod: "BANKBOSTON",
    name: "BANKBOSTON N.A.",
  },
  {
    cod: "BANCO_CITIBANK",
    name: "BANCO CITIBANK S.A.",
  },
  {
    cod: "BANCO_RABOBANK_INTERNATIONAL",
    name: "BANCO RABOBANK INTERNATIONAL B",
  },
  {
    cod: "BANCO_SIMPLES",
    name: "BANCO SIMPLES S.A.",
  },
  {
    cod: "BANCO_BNP_PARIBAS_BRASIL",
    name: "BANCO BNP PARIBAS BRASIL S.A",
  },
  {
    cod: "BANCO_COMERCIAL_URUGUAI",
    name: "BANCO COMERCIAL URUGUAI S.A.",
  },
  {
    cod: "BANCO_KEB_DO_BRASIL",
    name: "BANCO KEB DO BRASIL S.A.",
  },
  {
    cod: "BANCO_BRADESCO",
    name: "BANCO BRADESCO S.A.",
  },
  {
    cod: "BANCO_BEG",
    name: "BANCO BEG S.A",
  },
  {
    cod: "GOLDMAN_SACHS_DO_BRASIL_BANCO",
    name: "GOLDMAN SACHS DO BRASIL BANCO ",
  },
  {
    cod: "BANCO_MAXINVEST",
    name: "BANCO MAXINVEST S.A",
  },
  {
    cod: "BANCO_PORTO_SEGURO",
    name: "BANCO PORTO SEGURO S.A",
  },
  {
    cod: "BANCO_MERRILL_DE_INVESTIMENTOS",
    name: "BANCO MERRILL DE INVESTIMENTOS",
  },
  {
    cod: "BANCO_BRJ",
    name: "BANCO BRJ S.A",
  },
  {
    cod: "BANCO_FIAT",
    name: "BANCO FIAT S.A",
  },
  {
    cod: "BANCO_OURINVEST",
    name: "BANCO OURINVEST S.A",
  },
  {
    cod: "TRICURY",
    name: "TRICURY S.A",
  },
  {
    cod: "COOPERATIVA_CENTRAL_ECONOMIA",
    name: "COOPERATIVA CENTRAL ECONOMIA E",
  },
  {
    cod: "CREDIALINCA_COOP_DE_CREDITO_RURAL",
    name: "CREDIALINÇA COOP DE CREDITO RURAL",
  },
  {
    cod: "BANCO_NACIONAL_DO_DESENVOLVIME",
    name: "BANCO NACIONAL DO DESENVOLVIME",
  },
  {
    cod: "BANCO_STANDARD_DE_INVESTIMENTO",
    name: "BANCO STANDARD DE INVESTIMENTO",
  },
  {
    cod: "BANCO_AZTECA_DO_BRASIL",
    name: "BANCO AZTECA DO BRASIL S.A.",
  },
  {
    cod: "BANCO_DO_ESTADO_DE_ALAGOAS",
    name: "BANCO DO ESTADO DE ALAGOAS S.A",
  },
  {
    cod: "CREDIREAL_EM_ABSORCAO",
    name: "CREDIREAL -EM ABSORCAO",
  },
  {
    cod: "BANCO_DO_ESTADO_DO_ACRE",
    name: "BANCO DO ESTADO DO ACRE S.A.",
  },
  {
    cod: "PARAIBAN_BANCO_DA_PARAIBA",
    name: "PARAIBAN-BANCO DA PARAIBA S.A.",
  },
  {
    cod: "BANCO_DO_ESTADO_DO_MATO_GROSSO",
    name: "BANCO DO ESTADO DO MATO GROSSO",
  },
  {
    cod: "BANCO_BEA",
    name: "BANCO BEA S.A.",
  },
  {
    cod: "BANCO_J_SAFRA",
    name: "BANCO J. SAFRA S.A",
  },
  {
    cod: "BANCO_BEMGE",
    name: "BANCO BEMGE S.A.",
  },
  {
    cod: "BANCO_DO_ESTADO_DE_RONDONIA",
    name: "BANCO DO ESTADO DE RONDONIA S.",
  },
  {
    cod: "BANCO_ABB",
    name: "BANCO ABB SA",
  },
  {
    cod: "BANCO_BANEB",
    name: "BANCO BANEB SA",
  },
  {
    cod: "BES_INVESTIMENTO_DO_BRASIL",
    name: "BES INVESTIMENTO DO BRASIL SA",
  },
  {
    cod: "JBS_BANCO",
    name: "JBS BANCO S.A.",
  },
  {
    cod: "BANCO_ITABANCO",
    name: "BANCO ITABANCO S.A.",
  },
  {
    cod: "BANK_OF_AMERICA",
    name: "BANK OF AMERICA - BRASIL S.A.",
  },
  {
    cod: "CAIXA_ECONOMICA_ESTADUAL_DO_RI",
    name: "CAIXA ECONOMICA ESTADUAL DO RI",
  },
  {
    cod: "BANCO_NORCHEM",
    name: "BANCO NORCHEM S.A.",
  },
  {
    cod: "BANCO_INTER_ATLANTICO",
    name: "BANCO INTER-ATLANTICO S.A.",
  },
  {
    cod: "HSBC_INVESTMENT_BANK_BRASIL",
    name: "HSBC INVESTMENT BANK BRASIL S.",
  },
  {
    cod: "BANCO_FINANCIAL_PORTUGUES",
    name: "BANCO FINANCIAL PORTUGUES",
  },
  {
    cod: "BANCO_FICRISA_AXELRUD",
    name: "BANCO FICRISA AXELRUD S.A.",
  },
  {
    cod: "BANCO_AXIAL",
    name: "BANCO AXIAL S.A.",
  },
  {
    cod: "BANCO_SUL_AMERICA",
    name: "BANCO SUL AMERICA S.A.",
  },
  {
    cod: "BANCO_MARTINELLI",
    name: "BANCO MARTINELLI S.A.",
  },
  {
    cod: "BANCO_SISTEMA",
    name: "BANCO SISTEMA S.A.",
  },
  {
    cod: "BANCO_REGIONAL_MALCON",
    name: "BANCO REGIONAL MALCON S.A.",
  },
  {
    cod: "BANCO_ZOGBI",
    name: "BANCO ZOGBI S.A",
  },
  {
    cod: "BANCO_CREFISUL",
    name: "BANCO CREFISUL S.A.",
  },
  {
    cod: "BANCO_ICATU",
    name: "BANCO ICATU S.A.",
  },
  {
    cod: "BANCO_BOAVISTA_INTERATLANTICO",
    name: "BANCO BOAVISTA INTERATLANTICO",
  },
  {
    cod: "BANCO_INTERPART",
    name: "BANCO INTERPART S.A.",
  },
  {
    cod: "BANCO_CAMBIAL",
    name: "BANCO CAMBIAL SA",
  },
  {
    cod: "BANCO_BANCRED",
    name: "BANCO BANCRED S.A.",
  },
  {
    cod: "CC_UNICRED_BRASIL_CENTRAL",
    name: "CC UNICRED BRASIL CENTRAL",
  },
  {
    cod: "BANCO_WOORI_BANK_DO_BRASIL",
    name: "BANCO WOORI BANK DO BRASIL S.A",
  },
  {
    cod: "BANCO_DE_CREDITO_REAL_DE_MINAS",
    name: "BANCO DE CREDITO REAL DE MINAS",
  },
  {
    cod: "BANCO_EUROINVEST_S_A_EUROBANC",
    name: "BANCO EUROINVEST S.A. EUROBANC",
  },
  {
    cod: "BANCO_CIDADE",
    name: "BANCO CIDADE S.A.",
  },
  {
    cod: "BANCO_EMPRESARIAL",
    name: "BANCO EMPRESARIAL S.A.",
  },
  {
    cod: "MILBANCO",
    name: "MILBANCO S.A.",
  },
  {
    cod: "BANCO_GULFINVEST",
    name: "BANCO GULFINVEST S.A.",
  },
  {
    cod: "BANCO_INDUSCRED",
    name: "BANCO INDUSCRED S.A.",
  },
  {
    cod: "BANCO_BOREAL",
    name: "BANCO BOREAL S.A.",
  },
  {
    cod: "BANCO_BBM_COM_C_IMOB_CFI",
    name: "BANCO BBM-COM.C.IMOB.CFI S.A.",
  },
  {
    cod: "BANCO_PLANIBANC",
    name: "BANCO PLANIBANC S.A.",
  },
  {
    cod: "BANCO_BRASILEIRO_COMERCIAL",
    name: "BANCO BRASILEIRO COMERCIAL S.A",
  },
  {
    cod: "BCR_EM_ABSORCAO",
    name: "BCR/EM ABSORCAO",
  },
  {
    cod: "BANCO_DO_PROGRESSO",
    name: "BANCO DO PROGRESSO S.A.",
  },
  {
    cod: "BANCO_HNF",
    name: "BANCO HNF S.A.",
  },
  {
    cod: "BANCO_PONTUAL",
    name: "BANCO PONTUAL S.A.",
  },
  {
    cod: "BANCO_BFB",
    name: "BANCO BFB _ EM ABSORCAO",
  },
  {
    cod: "BANCO_DIGIBANCO",
    name: "BANCO DIGIBANCO S.A.",
  },
  {
    cod: "BANCO_ITAMARATI",
    name: "BANCO ITAMARATI S/A",
  },
  {
    cod: "BANCO_FENICIA",
    name: "BANCO FENICIA S.A.",
  },
  {
    cod: "BANCO_BMD",
    name: "BANCO BMD S.A.",
  },
  {
    cod: "BANCO_MERCANTIL_DE_SAO_PAULO",
    name: "BANCO MERCANTIL DE SAO PAULO S",
  },
  {
    cod: "BANCO_NACIONAL",
    name: "BANCO NACIONAL S.A.",
  },
  {
    cod: "BANORTE_BANCO_NACIONAL_DO_NO",
    name: "BANORTE - BANCO NACIONAL DO NO",
  },
  {
    cod: "LECCA_CRED_FINANC_E_INVEST",
    name: "LECCA CRÉD FINANC E INVEST S.A",
  },
  {
    cod: "BANFORT_BANCO_FORTALEZ",
    name: "BANFORT - BANCO FORTALEZA S.A.",
  },
  {
    cod: "LLOYDS_TSB_BANK",
    name: "LLOYDS TSB BANK PLC",
  },
  {
    cod: "BANCO_FINANCIAL_PORTUGUES_EM",
    name: "BANCO FINANCIAL PORTUGUES-EM A",
  },
  {
    cod: "BANCO_WACHOVIA",
    name: "BANCO WACHOVIA S.A.",
  },
  {
    cod: "BANCO_FRANCES",
    name: "BANCO FRANCES INTERNACIONAL-BR",
  },
  {
    cod: "BANCO_UNION",
    name: "BANCO UNION - BRASIL S.A",
  },
  {
    cod: "BANCO_UNO_E_BRASIL",
    name: "BANCO UNO-E BRASIL S.A",
  },
  {
    cod: "CENTRO_HISPANO_BANCO",
    name: "CENTRO HISPANO BANCO",
  },
  {
    cod: "BANCO_IOCHPE",
    name: "BANCO IOCHPE S.A.",
  },
  {
    cod: "BANCO_BRASILEIRO_IRAQUIANO",
    name: "BANCO BRASILEIRO IRAQUIANO S.A",
  },
  {
    cod: "BANCO_SANTANDER_DE_NEGOCIOS",
    name: "BANCO SANTANDER DE NEGOCIOS S.",
  },
  {
    cod: "BANCO_MULTIPLIC",
    name: "BANCO MULTIPLIC S.A.",
  },
  {
    cod: "BANCO_PATENTE",
    name: "BANCO PATENTE S.A.",
  },
  {
    cod: "BANCO_SANTOS_NEVES",
    name: "BANCO SANTOS NEVES S.A.",
  },
  {
    cod: "BANCO_TENDENCIA",
    name: "BANCO TENDENCIA S.A.",
  },
  {
    cod: "BANCO_APLICAP",
    name: "BANCO APLICAP S.A.",
  },
  {
    cod: "BANCO_GENERAL_MOTORS",
    name: "BANCO GENERAL MOTORS S.A",
  },
  {
    cod: "BANCO_ARAUCARIA",
    name: "BANCO ARAUCARIA S.A.",
  },
  {
    cod: "BANCO_DESTAK",
    name: "BANCO DESTAK S.A.",
  },
  {
    cod: "BANCO_CRITERIUM",
    name: "BANCO CRITERIUM S. A.",
  },
  {
    cod: "BANCO_DO_ESTADO_AMAPA",
    name: "BANCO DO ESTADO AMAPA S.A.",
  },
  {
    cod: "BANCO_CREDITO_METROPOLITANO",
    name: "BANCO CREDITO METROPOLITANO S/",
  },
  {
    cod: "BANCO_DO_ESTADO_DE_RORAIMA",
    name: "BANCO DO ESTADO DE RORAIMA S.A",
  },
  {
    cod: "BANCO_MARKA",
    name: "BANCO MARKA S.A.",
  },
  {
    cod: "BANCO_DIMENSAO",
    name: "BANCO DIMENSAO S.A.",
  },
  {
    cod: "BANCO_PEBB",
    name: "BANCO PEBB S.A.",
  },
  {
    cod: "BANCO_MATRIX",
    name: "BANCO MATRIX S.A.",
  },
  {
    cod: "BANCO_TECNICORP",
    name: "BANCO TECNICORP S.A.",
  },
  {
    cod: "BANCO_PORTO_REAL",
    name: "BANCO PORTO REAL S.A.",
  },
  {
    cod: "BANCO_SANTOS",
    name: "BANCO SANTOS S. A.",
  },
  {
    cod: "BANCO_VETOR",
    name: "BANCO VETOR S.A.",
  },
  {
    cod: "BANCO_VEGA",
    name: "BANCO VEGA S.A.",
  },
  {
    cod: "BANCO_OPERADOR",
    name: "BANCO OPERADOR S.A.",
  },
  {
    cod: "BANCO_INTERIOR_DE_SAO_PAULO",
    name: "BANCO INTERIOR DE SAO PAULO S.",
  },
  {
    cod: "BANCO_FINANSINOS",
    name: "BANCO FINANSINOS S. A.",
  },
  {
    cod: "BANCO_FITAL",
    name: "BANCO FITAL S.A.",
  },
  {
    cod: "BANCO_FONTE_CINDAM",
    name: "BANCO FONTE CINDAM S.A.",
  },
  {
    cod: "BANCO_MINAS",
    name: "BANCO MINAS S.A.",
  },
  {
    cod: "BANCO_DAS_NACOES",
    name: "BANCO DAS NACOES S.A.",
  },
  {
    cod: "BANCO_THECA",
    name: "BANCO THECA S.A.",
  },
  {
    cod: "BANCO_EQUATORIAL",
    name: "BANCO EQUATORIAL S.A.",
  },
  {
    cod: "HSBC_REPUBLIC_BANK_BRASIL",
    name: "HSBC REPUBLIC BANK BRASIL S.A-",
  },
  {
    cod: "BCR_BANCO_DE_CREDITO_REAL",
    name: "BCR BANCO DE CREDITO REAL S.A",
  },
  {
    cod: "BANCO_INTERMEDIUM",
    name: "BANCO INTERMEDIUM S.A.",
  },
  {
    cod: "BANCO_DA_CHINA_BRASIL",
    name: "BANCO DA CHINA BRASIL S.A.",
  },
  {
    cod: "NATIXIS_BRASIL",
    name: "NATIXIS BRASIL S.A. - BANCO M",
  },
  {
    cod: "BANCO_PETRA",
    name: "Banco Petra S.A.",
  },
  {
    cod: "CENTRAL_DE_COOPERATIVAS_DE_ECO",
    name: "CENTRAL DE COOPERATIVAS DE ECO",
  },
  {
    cod: "BANCO_WESTERN_UNION_DO_BRASIL",
    name: "BANCO WESTERN UNION DO BRASIL",
  },
  {
    cod: "CECOOPES_CENTRAL_DAS_COOP",
    name: "CECOOPES-CENTRAL DAS COOP DE E",
  },
  {
    cod: "BANCO_BERJ",
    name: "BANCO BERJ S.A",
  },
  {
    cod: "CONCORDIA_BANCO",
    name: "CONCORDIA BANCO S.A.",
  },
  {
    cod: "BANCO_TOPAZIO",
    name: "BANCO TOPÁZIO",
  },
  {
    cod: "OBOE_CREDITO_FINANCIAMENTO",
    name: "OBOE CREDITO, FINANCIAMENTO E",
  },
  {
    cod: "COOPERATIVA_CENTRAL_DE_CREDITO",
    name: "COOPERATIVA CENTRAL DE CREDITO",
  },
  {
    cod: "BANCO_BCN",
    name: "BANCO BCN S.A.",
  },
  {
    cod: "DEUTSCHE_BANK",
    name: "DEUTSCHE BANK S. A. - BANCO AL",
  },
  {
    cod: "BANCO_DE_LA_REPUBLICA_ORIENTAL",
    name: "BANCO DE LA REPUBLICA ORIENTAL",
  },
  {
    cod: "BANCO_LUSO_BRASILEIRO",
    name: "BANCO LUSO BRASILEIRO S.A.",
  },
  {
    cod: "BANCO_PAULISTA",
    name: "BANCO PAULISTA S.A.",
  },
  {
    cod: "BANCO_PECUNIA",
    name: "BANCO PECUNIA S.A.",
  },
  {
    cod: "BANCO_FICSA",
    name: "BANCO FICSA S.A.",
  },
  {
    cod: "BANCO_INTERCAP",
    name: "BANCO INTERCAP S.A.",
  },
  {
    cod: "BANCO_SOFISA",
    name: "BANCO SOFISA S.A.",
  },
  {
    cod: "BANCO_PINE",
    name: "BANCO PINE S.A.",
  },
  {
    cod: "BANCO_INDUSVAL",
    name: "BANCO INDUSVAL S.A.",
  },
  {
    cod: "BANCO_DAYCOVAL",
    name: "BANCO DAYCOVAL S.A.",
  },
  {
    cod: "BANCO_CREDIBEL",
    name: "BANCO CREDIBEL S.A.",
  },
  {
    cod: "BANCO_POTTENCIAL",
    name: "BANCO POTTENCIAL S.A.",
  },
  {
    cod: "BANCO_BARCLAYS",
    name: "BANCO BARCLAYS S.A.",
  },
  {
    cod: "BANCO_SEMEAR",
    name: "BANCO SEMEAR S.A.",
  },
  {
    cod: "BANCO_MODAL",
    name: "BANCO MODAL S.A.",
  },
  {
    cod: "BANCO_COOPERATIVO_SICREDI",
    name: "BANCO COOPERATIVO SICREDI S.A.",
  },
  {
    cod: "DRESDNER_BANK_BRASIL",
    name: "DRESDNER BANK BRASIL S.A. BANC",
  },
  {
    cod: "BANCO_COOPERATIVO_DO_BRASIL",
    name: "BANCO COOPERATIVO DO BRASIL S.",
  },
  {
    cod: "SUL_FINANCEIRA",
    name: "SUL FINANCEIRA",
  },
  {
    cod: "UNICRED",
    name: "UNICRED",
  },
  {
    cod: "CRESOL",
    name: "CRESOL",
  },
  {
    cod: "BANCO_6",
    name: "BANCO 6",
  },
  {
    cod: "NU_PAGAMENTOS",
    name: "NU PAGAMENTOS S.A.",
  },
  {
    cod: "FINANCEIRA_ALFA",
    name: "FINANCEIRA ALFA S/A - CREDITO",
  },
  {
    cod: "FACTA_FINANCEIRA",
    name: "FACTA FINANCEIRA",
  },
  {
    cod: "CREDICOAMO",
    name: "Credicoamo",
  },
  {
    cod: "MERCADO_PAGO",
    name: "MERCADO PAGO",
  },
  {
    cod: "PAG_SEGURO_INTERNET",
    name: "PAG SEGURO INTERNET",
  },
  {
    cod: "BANCO_523",
    name: "BANCO 523",
  },
  {
    cod: "C6_BANK",
    name: "C6 BANK S.A.",
  },
  {
    cod: "BECKER_FINANCEIRA",
    name: "BECKER FINANCEIRA",
  },
  {
    cod: "VALOR_SOCIEDADE",
    name: "VALOR SOCIEDADE",
  },
  {
    cod: "BANCO_OLE_BONSUCESSO",
    name: "BANCO OLE BONSUCESSO CONSIGNADO S/A",
  },
  {
    cod: "BANCO_INTER",
    name: "BANCO INTER",
  },
  {
    cod: "BANCO_335",
    name: "BANCO 335",
  },
  {
    cod: "CCB_BRASIL",
    name: "CCB BRASIL S/A",
  },
  {
    cod: "AGIBANK",
    name: "AGIBANK FINANCEIRA S/A",
  },
  {
    cod: "RETORNO_DATAPREV",
    name: "RETORNO DATAPREV",
  },
  {
    cod: "SICOOB_CREDICENM",
    name: "SICOOB CREDICENM",
  },
  {
    cod: "HS_FINANCEIRA",
    name: "HS FINANCEIRA S.A - CREDITO, FINANCIAMENTO E INVESTIMENTOS",
  },
  {
    cod: "ZEMA_CREDITO",
    name: "ZEMA CREDITO, FINANCIAMENTO E INVESTIMENTO S/A",
  },
  {
    cod: "CREFISA",
    name: "CREFISA CFI",
  },
  {
    cod: "PARATI_CREDITO",
    name: "PARATI CREDITO S/A",
  },
  {
    cod: "SANTINVEST",
    name: "SANTINVEST S/A",
  },
  {
    cod: "BARI",
    name: "BARI",
  },
  {
    cod: "ASPECIR",
    name: "ASPECIR",
  },
  {
    cod: "CREDIARE",
    name: "CREDIARE S/A CREDITO",
  },
  {
    cod: "BRB_CREDITOS",
    name: "BRB CREDITOS S/A",
  },
  {
    cod: "MERCANTIL_DO_BRASIL_FINANCEIRA",
    name: "MERCANTIL DO BRASIL FINANCEIRA S/A",
  },
  {
    cod: "GAZINCRED_S_A_SOCIEDADE_DE_CREDITO",
    name: "GAZINCRED S/A SOCIEDADE DE CREDITO",
  },
  {
    cod: "BANCO_SICOOB_COOPERNAPI",
    name: "BANCO SICOOB COOPERNAPI",
  },
  {
    cod: "DIGIO",
    name: "DIGIO",
  },
  {
    cod: "BANCO_273",
    name: "BANCO 273",
  },
  {
    cod: "BANCO_301",
    name: "BANCO 301",
  },
  {
    cod: "BANCO_276",
    name: "BANCO 276",
  },
  {
    cod: "BANCO_123",
    name: "BANCO 123",
  },
  {
    cod: "BANCO_326",
    name: "BANCO 326",
  },
  {
    cod: "BANCO_93",
    name: "BANCO 93",
  },
  {
    cod: "BANCO_CORA",
    name: "BANCO CORA",
  },
  {
    cod: "BANCO_363",
    name: "BANCO 363",
  },
  {
    cod: "BANCO_BARI_DE_INVESTIMENTOS_E_FINANCIAMENTOS",
    name: "BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS SA",
  },
  {
    cod: "AVISTA_S_A_CRÉDITO_FINANCIAMENTO_E_INVESTIMENTO",
    name: "AVISTA S.A CRÉDITO, FINANCIAMENTO E INVESTIMENTO",
  },
  {
    cod: "STONE_PAGAMENTOS",
    name: "STONE PAGAMENTOS S.A.",
  },
  {
    cod: "NAO_EXISTE_INFORMACAO",
    name: "NÃO EXISTE INFORMAÇÃO",
  },
  {
    cod: "BANCO_60",
    name: "BANCO 60",
  },
];

export const ESTADOS_JSON = [
  {
    nome: "Acre",
    sigla: "AC",
  },
  {
    nome: "Alagoas",
    sigla: "AL",
  },
  {
    nome: "Amapá",
    sigla: "AP",
  },
  {
    nome: "Amazonas",
    sigla: "AM",
  },
  {
    nome: "Bahia",
    sigla: "BA",
  },
  {
    nome: "Ceará",
    sigla: "CE",
  },
  {
    nome: "Distrito Federal",
    sigla: "DF",
  },
  {
    nome: "Espírito Santo",
    sigla: "ES",
  },
  {
    nome: "Goiás",
    sigla: "GO",
  },
  {
    nome: "Maranhão",
    sigla: "MA",
  },
  {
    nome: "Mato Grosso",
    sigla: "MT",
  },
  {
    nome: "Mato Grosso do Sul",
    sigla: "MS",
  },
  {
    nome: "Minas Gerais",
    sigla: "MG",
  },
  {
    nome: "Pará",
    sigla: "PA",
  },
  {
    nome: "Paraíba",
    sigla: "PB",
  },
  {
    nome: "Paraná",
    sigla: "PR",
  },
  {
    nome: "Pernambuco",
    sigla: "PE",
  },
  {
    nome: "Piauí",
    sigla: "PI",
  },
  {
    nome: "Rio de Janeiro",
    sigla: "RJ",
  },
  {
    nome: "Rio Grande do Norte",
    sigla: "RN",
  },
  {
    nome: "Rio Grande do Sul",
    sigla: "RS",
  },
  {
    nome: "Rondônia",
    sigla: "RO",
  },
  {
    nome: "Roraima",
    sigla: "RR",
  },
  {
    nome: "Santa Catarina",
    sigla: "SC",
  },
  {
    nome: "São Paulo",
    sigla: "SP",
  },
  {
    nome: "Sergipe",
    sigla: "SE",
  },
  {
    nome: "Tocantins",
    sigla: "TO",
  },
];

export const MESSAGE_VALIDATION_INPUT = {
  REQUERID_FIELD: "Campo Obrigatório!",
  INVALID_EMAIL_FIELD: "Email Inválido!",
  INVALID_DATE: "Formato de data inválida!",
};

export const DOMINIO_STATUS_COLABORADOR = Object.freeze({
  ATIVA: { cod: "ATIVA", name: "Ativo" },
  INATIVA: { cod: "INATIVA", name: "Inativo" },
});


export const DOMINIO_STATUS_ATIVO_INATIVO = Object.freeze({
  ATIVO: { cod: "ATIVO", name: "Ativo" },
  INATIVO: { cod: "INATIVO", name: "Inativo" },
});

export const DOMINIO_STATUS_EMPRESA = Object.freeze({
  ATIVA: { cod: "ATIVA", name: "Ativa" },
  SUSPENSA: { cod: "SUSPENSA", name: "Suspensa" },
  INATIVA: { cod: "INATIVA", name: "Inativa" },
});

export const DOMINIO_TIPO_PLANO = Object.freeze({
  BASIC: { cod: "BASIC", name: "Essencial" },
  STANDARD: { cod: "STANDARD", name: "Avançado" },
});

export const DOMINIO_TIPO_FATURAMENTO = Object.freeze({
  SIMPLES_NACIONAL: {
    cod: 0,
    desc: "Simples Nacional",
    name: "SIMPLES_NACIONAL",
  },
  LUCRO_REAL_PRESUMIDO: {
    cod: 1,
    desc: "Lucro Real/Presumido",
    name: "LUCRO_REAL_PRESUMIDO",
  },
});

export const DOMINIO_FREQUENCIA_COBRANCA = Object.freeze({
  MENSAL: { cod: "MENSAL", name: "Mensal" },
  TRIMESTRAL: { cod: "TRIMESTRAL", name: "Trimestral" },
  SEMESTRAL: { cod: "SEMESTRAL", name: "Semestral" },
  ANUAL: { cod: "ANUAL", name: "Anual" },
});

export const DOMINIO_ORIGEM_LEAD = [
  "Google ADS",
  "Busca Orgânica",
  "Tráfego Direto",
  "Eventos/Feiras",
  "Bing",
  "Facebook",
  "Instagram",
  "Indicação Cliente",
  "Indicação Outros",
  "SDR",
  "Comercial",
  "Trial",
  "Email Marketing",
  "Outros",
];

export const DOMINIO_SIM_NAO = Object.freeze({
  SIM: { cod: 1, desc: "Sim", name: "SIM" },
  NAO: { cod: 0, desc: "Não", name: "NAO" },
});

export const DOMINIO_DIAS_GERAR_FATURA = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
];

export const DOMINIO_MODO_COBRANCA = Object.freeze({
  GERADO_MES_MES: { cod: 1, desc: "Gerado Mês a Mês", name: "GERADO_MES_MES" },
  RECORENTE_CARTAO_CREDITO: {
    cod: 2,
    desc: "Recorrente no Cartão de Crédito",
    name: "RECORENTE_CARTAO_CREDITO",
  },
});

export const DOMINIO_SEVERITY_MENSAGEM = Object.freeze({
  ERROR: { cod: "error", desc: "Erro", name: "error" },
  WARNING: { cod: "warning", desc: "Atenção", name: "warning" },
  INFO: { cod: "info", desc: "Informativa", name: "info" },
  SUCCESS: { cod: "success", desc: "Sucesso", name: "success" },
});

export const DOMINIO_TIPO_MOTIVO_AJUSTE = Object.freeze({
  ABONO: { cod: 1, desc: "Abono", name: "ABONO" },
  AJUSTE_HORARIO: { cod: 2, desc: "Ajuste de horário", name: "AJUSTE_HORARIO" },
  BANCO_HORAS: {
    cod: 3,
    desc: "Considerar no banco de horas",
    name: "BANCO_HORAS",
  },
  DESCANSO_REMUNERADO: {
    cod: 4,
    desc: "Descanso semanal remunerado",
    name: "DESCANSO_REMUNERADO",
  },
  FALTA_DESCONTO: {
    cod: 5,
    desc: "Falta com desconto",
    name: "FALTA_DESCONTO",
  },
  IGNORAR_BANCO: {
    cod: 6,
    desc: "Abater do Banco de Horas sem Considerar Falta",
    name: "IGNORAR_BANCO",
  },
  SUSPENSAO: { cod: 7, desc: "Suspensão", name: "SUSPENSAO" },
});

export const DOMINIO_TIPO_PERFIL = Object.freeze({
  ADMINISTRADORA: { cod: 1, desc: "Administrador", name: "ADMINISTRADORA" },
  GESTOR: { cod: 2, desc: "Gestor", name: "GESTOR" },
  COLABORADOR: { cod: 4, desc: "Colaborador", name: "COLABORADOR" },
});

export const DOMINIO_TIPO_NOTIFICACAO = Object.freeze({
  EMAIL: { cod: 1, desc: "E-mail", name: "EMAIL" },
  SISTEMA: { cod: 2, desc: "Sistema", name: "SISTEMA" },
  PUSH: { cod: 4, desc: "PUSH (Via Aplicativo)", name: "PUSH" },
});

export const DOMINIO_ATIVO_INATIVO = Object.freeze({
  ATIVO: { cod: 1, desc: "Ativo", name: "ATIVO" },
  INATIVO: { cod: 0, desc: "Inativo", name: "INATIVO" },
});

export const DOMINIO_ATIVO_INATIVO_COLABORADOR = Object.freeze({
  ATIVO: { cod: 1, desc: "Ativo", name: "ATIVO" },
  INATIVO: { cod: 0, desc: "Desligado", name: "INATIVO" },
});

export const DOMINIO_PAPEL_PERFIL = Object.freeze({
  ADMINISTRADORA: { cod: 1, desc: "Administrador", name: "ADMINISTRADORA" },
  GESTOR: { cod: 2, desc: "Gestor", name: "GESTOR" },
  COLABORADOR: { cod: 3, desc: "Colaborador", name: "COLABORADOR" },
});

export const DOMINIO_PUBLICO_ALVO_MENSAGEM = Object.freeze({
  INDIVIDUAL: { cod: 1, desc: "Individual", name: "INDIVIDUAL" },
  TODOS: { cod: 2, desc: "Todos", name: "TODOS" },
  COLABORADORES: { cod: 5, desc: "Colaboradores", name: "COLABORADORES" },
  ADMINISTRADORES: { cod: 3, desc: "Administradores", name: "ADMINISTRADORES" },
  GESTORES: { cod: 4, desc: "Gestores", name: "GESTORES" },
  LOTACAO: { cod: 5, desc: "Lotações", name: "LOTACAO" },
  CENTRO_CUSTO: { cod: 6, desc: "Centros de Custo", name: "CENTRO_CUSTO" },
});

export const DOMINIO_MODELO_REP = Object.freeze({
  HENRY_PRISMA_HEXA_ADVANCED: {
    cod: 1,
    desc: "Henry - Prisma/Hexa Advanced",
    name: "HENRY_PRISMA_HEXA_ADVANCED",
  },
  HENRY_PRISMA_SUPER_FACIL_ADVANCED: {
    cod: 2,
    desc: "Henry - Prisma Super Fácil Advanced",
    name: "HENRY_PRISMA_SUPER_FACIL_ADVANCED",
  },
  INNER_REP_BIO_2I: {
    cod: 3,
    desc: "INNER REP BIO 2i",
    name: "INNER_REP_BIO_2I",
  },
  RWTECH: { cod: 4, desc: "RwTech", name: "RWTECH" },
  INNER_REP_PLUS_BIO_PROX: {
    cod: 5,
    desc: "INNER REP PLUS BIO PROX",
    name: "INNER_REP_PLUS_BIO_PROX",
  },
  KURUMIM_REP_BIO_NT_BR: {
    cod: 6,
    desc: "KURUMIM REP BIO NT BR",
    name: "KURUMIM_REP_BIO_NT_BR",
  },
  PRINT_POINT_III: { cod: 7, desc: "Print Point III", name: "PRINT_POINT_III" },
  CONTROL_ID: { cod: 9, desc: "Control ID", name: "CONTROL_ID" },
});



export const DOMINIO_TIPO_AFD_INTEGRACAO = Object.freeze({
  AFD: {
    cod: 1,
    desc: "AFD",
    name: "AFD",
  },
  AFD671: {
    cod: 2,
    desc: "AFD 671",
    name: "AFD671",
  },
});



export const DOMINIO_TIPO_ARQUIVO_AFD = Object.freeze({
  AFD: { cod: 1, desc: "Arquivo-Fonte de Dados (AFD) MP 1510", name: "AFD" },
  AFD671: {
    cod: 5,
    desc: "Arquivo-Fonte de Dados (AFD) MP 671",
    name: "AFD671",
  },
  AFD671ASSINADO: {
    cod: 5,
    desc: "Arquivo-Fonte de Dados (AFD) MP 671 (Assinado)",
    name: "AFD671ASSINADO",
  },
  AFDT: { cod: 2, desc: "Arquivo-Fonte de Dados Tratado (AFDT)", name: "AFDT" },
  ACJEF: {
    cod: 3,
    desc: "Arquivo de Controle de Jornada para Efeitos Fiscais (ACJEF)",
    name: "ACJEF",
  },
  AEJ: { cod: 4, desc: "Arquivo Elêtronico de Jornada (AEJ)", name: "AEJ" },
  AEJASSINADO: { cod: 4, desc: "Arquivo Elêtronico de Jornada (AEJ) (Assinado)", name: "AEJASSINADO" },
});

export const DOMINIO_TIPO_TURNOVER = Object.freeze({
  VOLUNTARIO: { cod: 1, desc: "Voluntário", name: "VOLUNTARIO" },
  INVOLUNTARIO: {
    cod: 2,
    desc: "Involuntário",
    name: "INVOLUNTARIO",
  },
});

export const DOMINIO_TIPO_AFASTAMENTO = Object.freeze({
  AFASTAMENTO: { cod: 1, desc: "Afastamento", name: "AFASTAMENTO" },
  FERIAS: { cod: 2, desc: "Férias", name: "FERIAS" },
});

export const DOMINIO_TIPO_AJUSTE_PONTO = Object.freeze({
  AJUSTE: { cod: 1, desc: "Ajuste", name: "AJUSTE" },
  ABONO: { cod: 2, desc: "Abono", name: "ABONO" },
});

export const DOMINIO_STATUS_AJUSTE_PONTO = Object.freeze({
  AGUARDANDO: { cod: 0, desc: "Aguardando Análise", name: "AGUARDANDO" },
  APROVADO: { cod: 1, desc: "Aprovado", name: "APROVADO" },
  REPROVADO: { cod: 2, desc: "Reprovado", name: "REPROVADO" },
  CANCELADA: { cod: 3, desc: "Cancelado", name: "CANCELADA" },
});


export const DOMINIO_MEIO_BATIDA = Object.freeze({
  APLICATIVO_MOVEL: { cod: 0, desc: "Aplicativo Móvel", name: "APLICATIVO_MOVEL" },
  NAVEGADOR: { cod: 1, desc: "Navegador", name: "NAVEGADOR" },
  REP: { cod: 2, desc: "REP", name: "REP" },
  TOTEN: { cod: 3, desc: "Totem", name: "TOTEN" },
});

export const DOMINIO_STATUS_COBRANCA = Object.freeze({
  AGUARDANDO_PAGAMENTO: {
    cod: 1,
    desc: "Aguardando Confirmação do Pagamento",
    name: "AGUARDANDO_PAGAMENTO",
  },
  PAGO: { cod: 2, desc: "Pago", name: "PAGO" },
  CANCELADO: { cod: 3, desc: "Cancelado", name: "CANCELADO" },
  VENCIDA: { cod: 4, desc: "Vencida", name: "VENCIDA" },
});

export const DOMINIO_TIPO_REPETE_EVENTO = Object.freeze({
  NUNCA: { cod: 1, desc: "Uma Vez", name: "NUNCA" },
  MENSAL: { cod: 2, desc: "Mensal", name: "MENSAL" },
  ANUAL: { cod: 3, desc: "Anual", name: "ANUAL" },
});

export const DOMINIO_STATUS_FECHAMENTO = Object.freeze({
  CONCLUIDO: { cod: 1, desc: "Concluido", name: "CONCLUIDO" },
  CANCELADO: { cod: 2, desc: "Cancelado", name: "CANCELADO" },
});

export const DOMINIO_TIPO_EVENTO_CALENDARIO = Object.freeze({
  FERIADO: { cod: 1, desc: "Feriado", name: "FERIADO" },
  ATUALIZACAO_SISTEMA: {
    cod: 5,
    desc: "Atualização do Sistema",
    name: "ATUALIZACAO_SISTEMA",
  },
  ACONTECIMENTO: { cod: 6, desc: "Acontecimento", name: "ACONTECIMENTO" },
});

export const DOMINIO_TIPO_ARQUIVO_COLABORADOR = Object.freeze({
  BONUS: { cod: 2, desc: "Bônus", name: "BONUS" },
  CERTIFICADOS: { cod: 8, desc: "Certificados", name: "CERTIFICADOS" },
  COMISSAO: { cod: 3, desc: "Comissão", name: "COMISSAO" },
  COMPROVANTE_AJUSTE_ABONO: {
    cod: 12,
    desc: "Comprovante de Ajuste/Abono de Ponto",
    name: "COMPROVANTE_AJUSTE_ABONO",
  },
  DOCUMENTOS: { cod: 9, desc: "Documentos", name: "DOCUMENTOS" },
  ENTREGA_EPI: { cod: 17, desc: "Entrega de EPI", name: "ENTREGA_EPI" },
  ENTREGA_UNIFORME: { cod: 16, desc: "Entrega de Uniforme", name: "ENTREGA_UNIFORME" },
  ESPELHO_PONTO: { cod: 14, desc: "Espelho do Ponto", name: "ESPELHO_PONTO" },
  EXAME_ADMINISSIONAL_DEMISSIONAL: {
    cod: 10,
    desc: "Exame Admissional/Demissional",
    name: "EXAME_ADMINISSIONAL_DEMISSIONAL",
  },
  
  FERIAS: { cod: 13, desc: "Férias", name: "FERIAS" },
  HOLERITE: { cod: 1, desc: "Holerite", name: "HOLERITE" },
  
  INFORME_DE_RENDIMENTOS: {
    cod: 4,
    desc: "Informe de Rendimentos",
    name: "INFORME_DE_RENDIMENTOS",
  },
  OUTROS: { cod: 11, desc: "Outros", name: "OUTROS" },  
  PLR: { cod: 7, desc: "PLR", name: "PLR" },
  RECIBOS: { cod: 6, desc: "Recibos", name: "RECIBOS" },
  SALARIO_13: { cod: 5, desc: "13º Salário", name: "SALARIO_13" },
});

export const DOMINIO_PERIODO_EXPERIENCIA = Object.freeze({
  P1X15DIAS: {
    cod: 1,
    desc: "1 x 15 dias",
    periodo: 1,
    dias: 15,
    name: "P1X15DIAS",
  },
  P1x30DIAS: {
    cod: 2,
    desc: "1 x 30 dias",
    periodo: 1,
    dias: 30,
    name: "P1x30DIAS",
  },
  P1x45DIAS: {
    cod: 3,
    desc: "1 x 45 dias",
    periodo: 1,
    dias: 45,
    name: "P1x45DIAS",
  },
  P1x60DIAS: {
    cod: 4,
    desc: "1 x 60 dias",
    periodo: 1,
    dias: 60,
    name: "P1x60DIAS",
  },
  P1x90DIAS: {
    cod: 5,
    desc: "1 x 90 dias",
    periodo: 1,
    dias: 90,
    name: "P1x90DIAS",
  },
  P2x15DIAS: {
    cod: 6,
    desc: "2 x 15 dias",
    periodo: 2,
    dias: 15,
    name: "P2x15DIAS",
  },
  P2X30DIAS: {
    cod: 7,
    desc: "2 x 30 dias",
    periodo: 2,
    dias: 30,
    name: "P2X30DIAS",
  },
  P2X45DIAS: {
    cod: 8,
    desc: "2 x 45 dias",
    periodo: 2,
    dias: 45,
    name: "P2X45DIAS",
  },
  P40X50DIAS: {
    cod: 10,
    desc: "40 x 50 dias",
    periodo: 3,
    diasPrimeiro: 40,
    diasSegundo: 50,
    dias: 90,
    name: "P40X50DIAS",
  },
  CUSTOMIZA: {
    cod: 9,
    desc: "Customizado",
    periodo: 2,
    dias: 90,
    name: "CUSTOMIZA",
  },
});

export const DOMINIO_TIPO_TURNO = Object.freeze({
  SEMANAL: { cod: 1, desc: "Semanal", name: "SEMANAL" },
  INTERMITENTE: { cod: 6, desc: "Intermitente", name: "INTERMITENTE" },
  H12H36: { cod: 2, desc: "12Hx36H", name: "H12H36" },
  D1D3: { cod: 3, desc: "1Dx3D", name: "D1D3" },
  D4D3: { cod: 9, desc: "4Dx3D", name: "D4D3" },
  D5D1: { cod: 4, desc: "5Dx1D", name: "D5D1" },
  D6D2: { cod: 5, desc: "6Dx2D", name: "D6D2" },
  D6D3: { cod: 8, desc: "6Dx3D", name: "D6D3" },
  D7D1: { cod: 7, desc: "7Dx1D", name: "D7D1" },
  D2D2: { cod: 10, desc: "2Dx2D", name: "D2D2" },
  D3D2D2D3: { cod: 11, desc: "3Dx2D - 2Dx3D", name: "D3D2D2D3" },
  D4D4D3D3: { cod: 12, desc: "4Dx4D - 3Dx3D", name: "D4D4D3D3" },
  D3D2D2D3D2D2: { cod: 13, desc: "3Dx2D - 2Dx3D - 2Dx2D", name: "D3D2D2D3D2D2" },
  D3D3D2D2: { cod: 14, desc: "3Dx3D - 2Dx2D", name: "D3D3D2D2" },
});

export const DOMINIO_STATUS_MUTUARIO = Object.freeze({
  ATIVO: { cod: 1, desc: "Ativo", name: "ATIVO" },
  DESLIGADO: { cod: 2, desc: "Desligado", name: "DESLIGADO" },
});

export const DOMINIO_FUSO_HORARIO = Object.freeze({
  UTC_DOZE: { cod: 12, desc: "12", longDesc: "(GMT 12:00)", name: "UTC_DOZE" },
  UTC_ONZE: { cod: 11, desc: "11", longDesc: "(GMT 11:00)", name: "UTC_ONZE" },
  UTC_DEZ: { cod: 10, desc: "10", longDesc: "(GMT 10:00)", name: "UTC_DEZ" },
  UTC_NOVE: { cod: 9, desc: "9", longDesc: "(GMT 09:00)", name: "UTC_NOVE" },
  UTC_OITO: { cod: 8, desc: "8", longDesc: "(GMT 08:00)", name: "UTC_OITO" },
  UTC_SETE: { cod: 7, desc: "7", longDesc: "(GMT 07:00)", name: "UTC_SETE" },
  UTC_SEIS: { cod: 6, desc: "6", longDesc: "(GMT 06:00)", name: "UTC_SEIS" },
  UTC_CINCO: { cod: 5, desc: "5", longDesc: "(GMT 05:00)", name: "UTC_CINCO" },
  UTC_QUATRO: {
    cod: 4,
    desc: "4",
    longDesc: "(GMT 04:00)",
    name: "UTC_QUATRO",
  },
  UTC_TRES: { cod: 3, desc: "3", longDesc: "(GMT 03:00)", name: "UTC_TRES" },
  UTC_DOIS: { cod: 2, desc: "2", longDesc: "(GMT 02:00)", name: "UTC_DOIS" },
  UTC_UM: { cod: 1, desc: "1", longDesc: "(GMT 01:00)", name: "UTC_UM" },
  UTC_ZERO: { cod: 0, desc: "0", longDesc: "(GMT 00:00)", name: "UTC_ZERO" },
  UTC_MENOS_UM: {
    cod: -1,
    desc: "-1",
    longDesc: "(GMT -01:00)",
    name: "UTC_MENOS_UM",
  },
  UTC_MENOS_DOIS: {
    cod: -2,
    desc: "-2",
    longDesc: "(GMT -02:00) Horário de Fernando de Noronha",
    name: "UTC_MENOS_DOIS",
  },
  UTC_MENOS_TRES: {
    cod: -3,
    desc: "-3",
    longDesc: "(GMT -03:00) Horário de Brasília",
    name: "UTC_MENOS_TRES",
  },
  UTC_MENOS_QUATRO: {
    cod: -4,
    desc: "-4",
    longDesc: "(GMT -04:00) Horário da Amazônia",
    name: "UTC_MENOS_QUATRO",
  },
  UTC_MENOS_CINCO: {
    cod: -5,
    desc: "-5",
    longDesc: "(GMT -05:00) Horário do Acre",
    name: "UTC_MENOS_CINCO",
  },
  UTC_MENOS_SEIS: {
    cod: -6,
    desc: "-6",
    longDesc: "(GMT -06:00)",
    name: "UTC_MENOS_SEIS",
  },
  UTC_MENOS_SETE: {
    cod: -7,
    desc: "-7",
    longDesc: "(GMT -07:00)",
    name: "UTC_MENOS_SETE",
  },
  UTC_MENOS_OITO: {
    cod: -8,
    desc: "-8",
    longDesc: "(GMT -08:00)",
    name: "UTC_MENOS_OITO",
  },
  UTC_MENOS_NOVE: {
    cod: -9,
    desc: "-9",
    longDesc: "(GMT -09:00)",
    name: "UTC_MENOS_NOVE",
  },
  UTC_MENOS_DEZ: {
    cod: -10,
    desc: "-10",
    longDesc: "(GMT -10:00)",
    name: "UTC_MENOS_DEZ",
  },
  UTC_MENOS_ONZE: {
    cod: -11,
    desc: "-11",
    longDesc: "(GMT -11:00)",
    name: "UTC_MENOS_ONZE",
  },
  UTC_MENOS_DOZE: {
    cod: -12,
    desc: "-12",
    longDesc: "(GMT -12:00)",
    name: "UTC_MENOS_DOZE",
  },
});

export const DOMINIO_TIPO_PERIODO = Object.freeze({
  ENTRADA: { cod: 1, desc: "Entrada", name: "ENTRADA" },
  SAIDA: { cod: 2, desc: "Saída", name: "SAIDA" },
});

export const DOMINIO_TIPO_OPERACAO_FOLHA = Object.freeze({
  TOTAL_HORAS_TRABALHADAS: {
    cod: 0,
    desc: "Total de horas trabalhadas",
    name: "TOTAL_HORAS_TRABALHADAS",
  },
  TOTAL_DIAS_ABONO: {
    cod: 3,
    desc: "Total de dias com Exceção/Abono",
    name: "TOTAL_DIAS_ABONO",
  },
  TOTAL_DIAS_FALTA: {
    cod: 4,
    desc: "Total de dias com falta",
    name: "TOTAL_DIAS_FALTA",
  },
  TOTAL_DIAS_FERIADOS_TRABALHADO: {
    cod: 5,
    desc: "Total de feriados trabalhados (dias)",
    name: "TOTAL_DIAS_FERIADOS_TRABALHADO",
  },
  TOTAL_HORAS_FERIADOS_TRABALHADO: {
    cod: 6,
    desc: "Total de feriados trabalhados (horas)",
    name: "TOTAL_HORAS_FERIADOS_TRABALHADO",
  },
  TOTAL_HORAS_FALTAS: {
    cod: 7,
    desc: "Total de horas em faltas",
    name: "TOTAL_HORAS_FALTAS",
  },
  TOTAL_HORAS_NOTURNAS: {
    cod: 8,
    desc: "Total de horas noturnas",
    name: "TOTAL_HORAS_NOTURNAS",
  },
  TOTAL_HORAS_EXTRAS: {
    cod: 9,
    desc: "Total de horas extras",
    name: "TOTAL_HORAS_EXTRAS",
  },
  TOTAL_HORAS_EXTRAS_1: {
    cod: 10,
    desc: "Total de horas extras 1",
    name: "TOTAL_HORAS_EXTRAS_1",
  },
  TOTAL_HORAS_EXTRAS_2: {
    cod: 11,
    desc: "Total de horas extras 2",
    name: "TOTAL_HORAS_EXTRAS_2",
  },
  TOTAL_HORAS_EXTRAS_3: {
    cod: 12,
    desc: "Total de horas extras 3",
    name: "TOTAL_HORAS_EXTRAS_3",
  },
  TOTAL_HORAS_ABONO: {
    cod: 14,
    desc: "Total de horas com Exceção/Abono",
    name: "TOTAL_HORAS_ABONO",
  },
  TOTAL_SALDO_FECHAMENTO: {
    cod: 17,
    desc: "Total de saldo horas do fechamento",
    name: "TOTAL_SALDO_FECHAMENTO",
  },
  TOTAL_SALDO_QUITAR_FECHAMENTO: {
    cod: 18,
    desc: "Total do saldo quitado no fechamento",
    name: "TOTAL_SALDO_QUITAR_FECHAMENTO",
  },
  TOTAL_HORAS_EXTRAS_EXCETO_FERIADO: {
    cod: 19,
    desc: "Total de horas extras, exceto feriados",
    name: "TOTAL_HORAS_EXTRAS_EXCETO_FERIADO",
  },
  TOTAL_SALDO_QUITAR_FECHAMENTO_POSITIVO: {
    cod: 20,
    desc: "Total de horas positivas, exceto feriados",
    name: "TOTAL_SALDO_QUITAR_FECHAMENTO_POSITIVO",
  },
  TOTAL_SALDO_QUITAR_FECHAMENTO_NEGATIVO: {
    cod: 21,
    desc: "Total de horas negativas, exceto feriados",
    name: "TOTAL_SALDO_QUITAR_FECHAMENTO_NEGATIVO",
  },
  TOTAL_DE_DIAS_COM_FALTA_EM_HORAS: {
    cod: 22,
    desc: "Total de dias com falta em horas",
    name: "TOTAL_DE_DIAS_COM_FALTA_EM_HORAS",
  },
  TOTAL_DE_HORAS_NORMAIS: {
    cod: 23,
    desc: "Total de Horas Normais",
    name: "TOTAL_DE_HORAS_NORMAIS",
  },
  ABONOS_DE_PONTO: {
    cod: 24,
    desc: "Abonos de Ponto",
    name: "ABONOS_DE_PONTO",
  },

  TOTAL_HORAS_EXTRAS_NOTURNA_1: {
    cod: 25,
    desc: "Total de horas extras noturnas 1",
    name: "TOTAL_HORAS_EXTRAS_NOTURNA_1",
  },
  TOTAL_HORAS_EXTRAS_NOTURNA_2: {
    cod: 26,
    desc: "Total de horas extras noturnas 2",
    name: "TOTAL_HORAS_EXTRAS_NOTURNA_2",
  },
  TOTAL_HORAS_NOTURNAS_SOMANDO_INTERVALO: {
    cod: 27,
    desc: "Total de horas noturnas somados do intervalo",
    name: "TOTAL_HORAS_NOTURNAS_SOMADOS_INTERVALO",
  },
  TOTAL_HORAS_EXTRAS_LIMITANDO_QUANTIA: {
    cod: 28,
    desc: "Total de horas extras, limitando a quantidade",
    name: "TOTAL_HORAS_EXTRAS_LIMITANDO_QUANTIA",
  },
  TOTAL_HORAS_EXTRAS_SALDO_LIMITADO_QUANTIA: {
    cod: 29,
    desc: "Total de horas extras, saldo remanescente limitando a quantidade",
    name: "TOTAL_HORAS_EXTRAS_SALDO_LIMITADO_QUANTIA",
  },
  TOTAL_HORAS_EXTRAS_ENTRE_QUANTIA: {
    cod: 30,
    desc: "Total de horas extras, entre dois intervalos",
    name: "TOTAL_HORAS_EXTRAS_ENTRE_QUANTIA",
  },
  TOTAL_DIAS_TRABALHADOS_EXCETO_FERIADOS: {
    cod: 31,
    desc: "Total de dias trabalhados exceto feriado",
    name: "TOTAL_DIAS_TRABALHADOS_EXCETO_FERIADOS",
  },
  TOTAL_HORAS_NOTURNAS_REDUZIDAS: {
    cod: 32,
    desc: "Total de horas noturnas reduzidas",
    name: "TOTAL_HORAS_NOTURNAS_REDUZIDAS",
  },
  TOTAL_ADICIONAL_NOTURNO_EXCETE_EXTRAS_NOTURNO: {
    cod: 33,
    desc: "Total de Adicional Noturna, exceto as horas extras noturnas",
    name: "TOTAL_ADICIONAL_NOTURNO_EXCETE_EXTRAS_NOTURNO",
  },
  TEMPO_SOBREAVISO: {
    cod: 34,
    desc: "Tempo de Sobreaviso",
    name: "TEMPO_SOBREAVISO",
  },
  HORA_EXTRA_INTRAJORNADA: {
    cod: 35,
    desc: "Hora extra intrajornada",
    name: "HORA_EXTRA_INTRAJORNADA",
  },
  HORA_EXTRA_INTERJORNADA: {
    cod: 36,
    desc: "Hora extra interjornada",
    name: "HORA_EXTRA_INTERJORNADA",
  },
  TOTAL_HORAS_NOTURNAS_SOMANDO_INTERVALO_SEM_REDUCAO: {
    cod: 37,
    desc: "Total de horas noturnas somado o intervalo sem redução",
    name: "TOTAL_HORAS_NOTURNAS_SOMANDO_INTERVALO_SEM_REDUCAO",
  },
  TOTAL_HORA_EXTRA_NOTURNA_1_REDUZIDA: {
    cod: 40,
    desc: "Horas Extras Noturnas 1 Reduzidas",
    name: "TOTAL_HORA_EXTRA_NOTURNA_1_REDUZIDA",
  },
  TOTAL_HORA_EXTRA_NOTURNA_2_REDUZIDA: {
    cod: 41,
    desc: "Horas Extras Noturnas 2 Reduzidas",
    name: "TOTAL_HORA_EXTRA_NOTURNA_2_REDUZIDA",
  },
  TOTAL_HORA_EXTRA_2_EXCETO_FERIADO: {
    cod: 42,
    desc: "Horas Extras 2 Exceto feriado",
    name: "TOTAL_HORA_EXTRA_2_EXCETO_FERIADO",
  },
  TOTAL_HORAS_EXTRAS_NOTURNA_1_EXCETO_FERIADO: {
    cod: 43,
    desc: "Total de Horas Extras Nortunas 1 Exceto Feriados",
    name: "TOTAL_HORAS_EXTRAS_NOTURNA_1_EXCETO_FERIADO",
  },
  TOTAL_HORAS_EXTRAS_NOTURNA_2_EXCETO_FERIADO: {
    cod: 44,
    desc: "Total de Horas Extras Nortunas 2 Exceto Feriados",
    name: "TOTAL_HORAS_EXTRAS_NOTURNA_2_EXCETO_FERIADO",
  },
  TOTAL_SALDO_QUITAR_FECHAMENTO_LIMITANDO_QTDE: {
    cod: 45,
    desc: "Total do saldo quitado, limitando quantidade",
    name: "TOTAL_SALDO_QUITAR_FECHAMENTO_LIMITANDO_QTDE",
  },
  TOTAL_SALDO_QUITAR_FECHAMENTO_REMANESCENTE: {
    cod: 46,
    desc: "Total do saldo quitado, limitando quantidade remanescente",
    name: "TOTAL_SALDO_QUITAR_FECHAMENTO_REMANESCENTE",
  },
  TOTAL_HORAS_EXTRAS_NOTURNA_2_SOMENTE_FERIADO: {
    cod: 47,
    desc: "Total de Horas Extras Noturnas 2, somente feriado",
    name: "TOTAL_HORAS_EXTRAS_NOTURNA_2_SOMENTE_FERIADO",
  },
  
});

export const DOMINIO_TIPO_CAMPO = Object.freeze({
  TEXTO: {
    preDefinido: false,
    cod: 1,
    desc: "Texto",
    name: "TEXTO",
    icon: <BsInputCursorText size={20} />,
  },
  TEXTO_LONGO: {
    preDefinido: false,
    cod: 2,
    desc: "Texto Longo",
    name: "TEXTO_LONGO",
    icon: <BsTextarea size={20} />,
  },
  NUMERO: {
    preDefinido: false,
    cod: 3,
    desc: "Número",
    name: "NUMERO",
    icon: <ImSortNumbericDesc size={20} />,
  },
  EMAIL: {
    preDefinido: false,
    cod: 4,
    desc: "Email",
    name: "EMAIL",
    icon: <AiOutlineMail size={20} />,
  },
  TELEFONE: {
    preDefinido: false,
    cod: 5,
    desc: "Telefone",
    name: "TELEFONE",
    icon: <AiOutlinePhone size={20} />,
  },
  CAIXA_SELECAO: {
    preDefinido: true,
    cod: 6,
    desc: "Caixa de Seleção",
    name: "CAIXA_SELECAO",
    icon: <VscListSelection size={20} />,
  },
  CLASSIFICACAO: {
    preDefinido: false,
    cod: 7,
    desc: "Classificação",
    name: "CLASSIFICACAO",
    icon: <BsStarHalf size={20} />,
  },
  DATA: {
    preDefinido: false,
    cod: 8,
    desc: "Data",
    name: "DATA",
    icon: <BsCalendarDate size={20} />,
  },
  MULTIPLA_ESCOLHA: {
    preDefinido: true,
    cod: 9,
    desc: "Múltipla Escolha",
    name: "MULTIPLA_ESCOLHA",
    icon: <BsListOl size={20} />,
  },
});

export const DOMINIO_LAYOUT_EXPORTACAO_FOLHA = Object.freeze({
  DOMINIO_SISTEMAS: {
    cod: 1,
    desc: "Domínio Sistemas",
    name: "DOMINIO_SISTEMAS",
  },
  DATASUL_SISTEMAS: {
    cod: 2,
    desc: "DataSul Sistemas",
    name: "DATASUL_SISTEMAS",
  },
  PUBLICA_SENIOR: { cod: 3, desc: "Pública (Senior)", name: "PUBLICA_SENIOR" },
  SENIOR_SISTEMAS: { cod: 4, desc: "Senior Sistemas", name: "SENIOR_SISTEMAS" },
  CUCA_FRESCA: { cod: 5, desc: "Cuca Fresca", name: "CUCA_FRESCA" },
  QUESTOR_SISTEMAS: { cod: 6, desc: "Questor", name: "QUESTOR_SISTEMAS" },
  TOTVS_SISTEMAS: { cod: 7, desc: "Totvs", name: "TOTVS_SISTEMAS" },
  ALTERDATA_SISTEMAS: { cod: 8, desc: "Alterdata", name: "ALTERDATA_SISTEMAS" },
  LG_SISTEMAS: { cod: 9, desc: "LG Sistemas", name: "LG_SISTEMAS" },
  SULPROG_SISTEMAS: {
    cod: 10,
    desc: "Sulprog Sistemas",
    name: "SULPROG_SISTEMAS",
  },
  PROSOFT: { cod: 11, desc: "Prosoft", name: "PROSOFT" },
  AO3: { cod: 12, desc: "AO3", name: "AO3" },
  SCI: { cod: 13, desc: "SCI - Unico de folha", name: "SCI" },
  CONSISTEM: { cod: 14, desc: "Consistem", name: "CONSISTEM" },
  VISUAL_PRATICE: { cod: 15, desc: "Visual Practice", name: "VISUAL_PRATICE" },
  SHX: { cod: 16, desc: "SHX", name: "SHX" },
  PROTHEUS: { cod: 17, desc: "Protheus", name: "PROTHEUS" },
  SANKYA: { cod: 18, desc: "Sankhya", name: "SANKYA" },
  PROSIS: { cod: 19, desc: "Prosis", name: "PROSIS" },
  CONTA_MAIS: { cod: 20, desc: "Conta Mais", name: "CONTA_MAIS" },
  METADADOS: { cod: 21, desc: "Metadados", name: "metadados_abono" },
  SYSTEM: { cod: 22, desc: "System", name: "SYSTEM" },
  NOVO_SISTEMA: { cod: 23, desc: "Novo Sistema", name: "NOVO_SISTEMA" },
  IOB: { cod: 24, desc: "IOB Gestão Contábil", name: "IOB" },
  DATACEMPRO: { cod: 25, desc: "Data Cempro", name: "DATACEMPRO" },
  CONTIMATIC: { cod: 26, desc: "Contimatic", name: "CONTIMATIC" },
  CONTABIT: { cod: 27, desc: "Contabit", name: "CONTABIT" },
  FORTES: { cod: 28, desc: "Fortes", name: "FORTES" },
});

export const MONTHLY_PRICING_TABLE = [
  {
    name: "1º Plano: Avançado",
    price: "R$ 92,00",
    consultor: false,
    priceLabel:
      "Valor do plano mensal para equipes pequenas de 1 até 10 colaboradores",
    marginTop: 30,
    dominio: "PLANO_2",
    listItems: [
      {
        content: "Reconhecimento facial",
      },
      {
        content: "Geolocalização",
      },
      {
        content: "Batida de Ponto offline",
      },
      {
        content: "Acesso ao Aplicativo Para Tablet/Totens",
      },
      {
        content: "Distribuição de Documentos e Holerites",
      },
      {
        content: "Controle de Férias",
      },
    ],
  },
  {
    name: "2º Plano: Avançado",
    price: "R$ 138,00",
    dominio: "PLANO_3",
    consultor: false,
    priceLabel:
      "Valor do plano mensal para equipes médias de 11 até 20 colaboradores",
    marginTop: 5,
    listItems: [
      {
        content: "Reconhecimento facial",
      },
      {
        content: "Geolocalização",
      },
      {
        content: "Batida de Ponto offline",
      },
      {
        content: "Acesso ao Aplicativo Para Tablet/Totens",
      },
      {
        content: "Distribuição de Documentos e Holerites",
      },
      {
        content: "Controle de Férias",
      },
      {
        content: "API de Integração",
      },
    ],
  },
  {
    name: "3º Plano: Avançado por colaborador",
    price: "R$ 6,20",
    dominio: "PLANO_4",
    consultor: false,
    porColaborador: true,
    priceLabel:
      "Valor do plano mensal para equipes de 21 até 50 colaboradores.",
    marginTop: 0,
    listItems: [
      {
        content: "Reconhecimento facial",
      },
      {
        content: "Geolocalização",
      },
      {
        content: "Batida de Ponto offline",
      },
      {
        content: "Acesso ao Aplicativo Para Tablet/Totens",
      },
      {
        content: "Distribuição de Documentos e Holerites",
      },
      {
        content: "Controle de Férias",
      },
      {
        content: "API de Integração",
      },
      ,
    ],
  },
  {
    name: "4º Plano: Avançado",
    price: " - ",
    consultor: true,
    priceLabel: "Para equipes acima de 51 colaboradores",
    buttonLabel: "FALE COM UM CONSULTOR",
    dominio: "PLANO_5",
    url: "https://pontoicarus.com.br/contato",
    marginTop: 60,
    listItems: [
      {
        content: "Reconhecimento facial",
      },
      {
        content: "Geolocalização",
      },
      {
        content: "Batida de Ponto offline",
      },
      {
        content: "Acesso ao Aplicativo Para Tablet/Totens",
      },
      {
        content: "Distribuição de Documentos e Holerites",
      },
      {
        content: "Controle de Férias",
      },
      {
        content: "API de Integração",
      },
      ,
    ],
  },
];
