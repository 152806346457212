import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";


import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { StylesProvider } from "@mui/styles";
import jssPreset from '@mui/styles/jssPreset';
import * as Yup from "yup";
import Alert from "./components/alert/Alert";
import AlertComponent from "./components/alert/AlertComponent";
import Routes from "./routes/Routes";
import createTheme from "./theme";


Yup.date().default(null);

Yup.setLocale({
  mixed: {
    default: "é inválido",
    required: "é um campo obrigatório",
    oneOf: "deve ser um dos seguintes valores: ${values}",
    notOneOf: "não pode ser um dos seguintes valores: ${values}",
  },
  string: {
    length: "deve ter exatamente ${length} caracteres",
    min: "deve ter pelo menos ${min} caracteres",
    max: "deve ter no máximo ${max} caracteres",
    email: "tem o formato de e-mail inválido",
    url: "deve ter um formato de URL válida",
    trim: "não deve conter espaços no início ou no fim.",
    lowercase: "deve estar em maiúsculo",
    uppercase: "deve estar em minúsculo",
  },
  number: {
    min: "deve ser no mínimo ${min}",
    max: "deve ser no máximo ${max}",
    lessThan: "deve ser menor que ${less}",
    moreThan: "deve ser maior que ${more}",
    notEqual: "não pode ser igual à ${notEqual}",
    positive: "deve ser um número posítivo",
    negative: "deve ser um número negativo",
    integer: "deve ser um número inteiro",
  },
  date: {
    min: "deve ser maior que a data ${min}",
    max: "deve ser menor que a data ${max}",
    typeError: "formato de data inválida",
  },
  array: {
    min: "deve ter no mínimo ${min} itens",
    max: "deve ter no máximo ${max} itens",
  },
});

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {

  const theme = createTheme("GREEN");

  const { user } = useSelector((state) => state.authReducer);

  useEffect(() => {
    if (user && user.profile && user.profile.papel !== "COLABORADOR") {
     
      const scriptVar = document.createElement("script");
      scriptVar.id = "octadesk";
      scriptVar.text = "(function (o, c, t, a, d, e, s, k) {o.octadesk = o.octadesk || {};o.octadesk.chatOptions = {subDomain: a,showButton: d,openOnMessage: e,hide: s}; var bd = c.getElementsByTagName(\"body\")[0]; var sc = c.createElement(\"script\");      sc.async = 1;      sc.src = t;      bd.appendChild(sc);})(window, document, 'https://cdn.octadesk.com/embed.js', 'o163840-832', 'true', 'true', 'false');";
      scriptVar.async = true;
      document.body.appendChild(scriptVar);

      const script = document.createElement("script");
      script.id = "octadeskIdentify";
      script.text = 'window.addEventListener("onOctaChatReady", function () { octadesk.chat.login({name: "' + user.person.nome +'", email: "'+user.person.email+'"});});';
      script.async = true;
      document.body.appendChild(script);
      
    }
  }, [user]);

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Ponto Icarus - Controle de Ponto Online"
        defaultTitle="Ponto Icarus - Controle de Ponto Online"
      />
      <StylesProvider jss={jss}>
        {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}

          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <Routes />
              <AlertComponent ref={(ref) => Alert.setAlertRef(ref)} />
            </ThemeProvider>
          </MuiThemeProvider>

        {/*</MuiPickersUtilsProvider>*/}
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
