import { Box, CircularProgress, Grid } from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import Alert from "../../../components/alert/Alert";
import Dialog from "../../../components/Dialog";
import AlterarButton from "../../../components/icarus/buttons/AlterarButton";
import CadastrarButton from "../../../components/icarus/buttons/CadastrarButton";
import CancelarButton from "../../../components/icarus/buttons/CancelarButton";
import TextField from "../../../components/icarus/textfield/TextField";
import TextFieldCPFCNPJ from "../../../components/icarus/textfield/TextFieldCPFCNPJ";
import CentroCustoService from "../../../services/CentroCustoService";
import MaskUtil from "../../../utils/MaskUtil";
import InformacoesFotoCentroCusto from "./InformacoesFotoCentroCusto";
import { MESSAGE_VALIDATION_INPUT } from "../../../constants";

const validationSchema = Yup.object().shape({
  codigo: Yup.string().required().max(30).min(1),
  sigla: Yup.string().max(30).min(1).nullable(),
  descricao: Yup.string().required().max(255).min(3).nullable(),
  endereco: Yup.string().max(255).min(5).nullable(),
  cnpj: Yup.string().required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD).nullable(),
  email: Yup.string().email().nullable(),
  telefone: Yup.number().positive().nullable(),
  cei: Yup.number().positive().nullable(),
  numeroRep: Yup.string().max(17).nullable(),
});

function DialogCentroCusto({ show = false, onRequestClose, data, initValue }) {
  const [showDialog, setShowDialog] = useState(show);
  const [carregando, setCarregando] = useState(false);

  const handleClose = () => {
    setShowDialog(false);
    onRequestClose();
  };

  const createCentroCusto = (values) => {
    const {
      cno,
      caepf,
      codigo,
      descricao,
      sigla,
      endereco,
      cnpj,
      email,
      telefone,
      cei,
    } = values;
    CentroCustoService.createCentroCusto({
      codigo,
      cno,
      caepf,
      descricao,
      sigla,
      endereco,
      cnpj,
      email,
      telefone,
      cei,
    })
      .then((response) => {
        Alert.success("", "Sucesso ao incluir o Centro de Custo");
        handleClose();
      })
      .finally(() => setCarregando(false));
  };

  const updateCentroCusto = (values) => {
    const {
      idCentroCusto,
      cno,
      caepf,
      codigo,
      descricao,
      sigla,
      endereco,
      cnpj,
      email,
      telefone,
      cei,
      numeroRep,
    } = values;
    CentroCustoService.updateCentroCusto({
      idCentroCusto,
      cno,
      caepf,
      codigo,
      descricao,
      sigla,
      endereco,
      cnpj,
      email,
      telefone,
      cei,
      numeroRep,
    })
      .then((response) => {
        Alert.success("", "Sucesso ao atualizar o Centro de Custo");
        handleClose();
      })
      .finally(() => setCarregando(false));
  };

  const renderCarregando = () => {
    return (
      <Box display="flex" justifyContent="center" my={6}>
        <CircularProgress />
      </Box>
    );
  };

  return (
    <Dialog
      title={`Centro de Custo - ${!data ? "Incluir" : "Alterar"}`}
      show={showDialog}
      onRequestClose={onRequestClose}
    >
      <Formik
        initialValues={{
          idCentroCusto: !data ? undefined : data.idCentroCusto,
          codigo: !data ? undefined : data.codigo,
          sigla: !data ? undefined : data.sigla,
          descricao: !data ? initValue : data.descricao,
          endereco: !data ? undefined : data.endereco,
          cnpj: !data ? undefined : data.cnpj,
          email: !data ? undefined : data.email,
          telefone: !data ? undefined : data.telefone,
          cei: !data ? undefined : data.cei,
          numeroRep: !data ? undefined : data.numeroRep,
          logomarca: !data ? undefined : data.logomarca,
          cno: !data ? undefined : data.cno,
          caepf: !data ? undefined : data.caepf,
          submit: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setCarregando(true);
          if (!data) {
            createCentroCusto(values);
          } else {
            updateCentroCusto(values);
          }
        }}
      >
        {(formik) => (
          <form noValidate onSubmit={formik.handleSubmit}>
            <Grid container spacing={6}>
              {formik.values.idCentroCusto && (
                <>
                  <Grid item md={12} sm={12} xs={12}>
                    <InformacoesFotoCentroCusto
                      idCentroCusto={formik.values.idCentroCusto}
                      caminhoFoto={formik.values.logomarca}
                    />
                  </Grid>
                </>
              )}

              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="codigo"
                  label="Código"
                  value={formik.values.codigo}
                  fullWidth
                  my={10}
                  required
                  error={Boolean(formik.touched.codigo && formik.errors.codigo)}
                  helperText={formik.touched.codigo && formik.errors.codigo}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="sigla"
                  label="Sigla"
                  value={formik.values.sigla}
                  fullWidth
                  my={10}
                  error={Boolean(formik.touched.sigla && formik.errors.sigla)}
                  helperText={formik.touched.sigla && formik.errors.sigla}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="descricao"
                  required
                  label="Descrição"
                  value={formik.values.descricao}
                  fullWidth
                  my={10}
                  error={Boolean(
                    formik.touched.descricao && formik.errors.descricao
                  )}
                  helperText={
                    formik.touched.descricao && formik.errors.descricao
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="endereco"
                  label="Endereço"
                  value={formik.values.endereco}
                  fullWidth
                  my={10}
                  error={Boolean(
                    formik.touched.endereco && formik.errors.endereco
                  )}
                  helperText={formik.touched.endereco && formik.errors.endereco}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextFieldCPFCNPJ
                  required
                  name="cnpj"
                  label="CPF/CNPJ"
                  value={formik.values.cnpj}
                  fullWidth
                  my={10}
                  error={Boolean(formik.touched.cnpj && formik.errors.cnpj)}
                  helperText={formik.touched.cnpj && formik.errors.cnpj}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.setFieldValue("cnpj", e, true);
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="email"
                  type="email"
                  label="Email"
                  value={formik.values.email}
                  fullWidth
                  my={10}
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="telefone"
                  label="Telefone"
                  value={MaskUtil.cellPhone(formik.values.telefone)}
                  fullWidth
                  my={10}
                  error={Boolean(
                    formik.touched.telefone && formik.errors.telefone
                  )}
                  helperText={formik.touched.telefone && formik.errors.telefone}
                  onBlur={formik.handleBlur}
                  onChange={(ret) => {
                    formik.setFieldValue(
                      "telefone",
                      MaskUtil.undoMask(ret.target.value),
                      true
                    );
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="cei"
                  label="CEI"
                  value={formik.values.cei}
                  fullWidth
                  my={10}
                  error={Boolean(formik.touched.cei && formik.errors.cei)}
                  helperText={formik.touched.cei && formik.errors.cei}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="cno"
                  label="CNO"
                  value={formik.values.cno}
                  fullWidth
                  my={10}
                  error={Boolean(formik.touched.cno && formik.errors.cno)}
                  helperText={formik.touched.cno && formik.errors.cno}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="caepf"
                  label="CAEPF"
                  value={formik.values.caepf}
                  fullWidth
                  my={10}
                  error={Boolean(formik.touched.caepf && formik.errors.caepf)}
                  helperText={formik.touched.caepf && formik.errors.caepf}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="numeroRep"
                  label="Número do REP"
                  value={formik.values.numeroRep}
                  fullWidth
                  my={10}
                  inputProps={{ maxLength: 17 }}
                  error={Boolean(
                    formik.touched.numeroRep && formik.errors.numeroRep
                  )}
                  helperText={
                    formik.touched.numeroRep && formik.errors.numeroRep
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>
            <Box my={2} />
            {carregando ? (
              renderCarregando()
            ) : (
              <Grid container spacing={2}>
                <Grid item>
                  <CancelarButton onClick={handleClose} />
                </Grid>
                <Grid item>
                  {!data ? <CadastrarButton /> : <AlterarButton />}
                </Grid>
              </Grid>
            )}
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

export default DialogCentroCusto;
