import { Box, CircularProgress, Grid } from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import Alert from "../../../components/alert/Alert";
import Dialog from "../../../components/Dialog";
import AlterarButton from "../../../components/icarus/buttons/AlterarButton";
import CadastrarButton from "../../../components/icarus/buttons/CadastrarButton";
import CancelarButton from "../../../components/icarus/buttons/CancelarButton";
import TextField from "../../../components/icarus/textfield/TextField";
import TextFieldCNPJ from "../../../components/icarus/textfield/TextFieldCNPJ";
import TextFieldTelefone from "../../../components/icarus/textfield/TextFieldTelefone";
import { MESSAGE_VALIDATION_INPUT } from "../../../constants";
import LotacaoService from "../../../services/LotacaoService";

const validationSchema = Yup.object().shape({
  codigo: Yup.string()
    .required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD)
    .min(1)
    .max(30),
  sigla: Yup.string().min(1).max(30).nullable(),
  descricao: Yup.string()
    .required(MESSAGE_VALIDATION_INPUT.REQUERID_FIELD)
    .min(3)
    .max(255),
  endereco: Yup.string().min(5).max(255).nullable(),
  email: Yup.string()
    .email(MESSAGE_VALIDATION_INPUT.INVALID_EMAIL_FIELD)
    .nullable(),
  telefone: Yup.number().positive().nullable(),
});

function DialogLotacao({ show = false, onRequestClose, data, initValue }) {
  const [showDialog, setShowDialog] = useState(show);
  const [carregando, setCarregando] = useState(false);

  const handleClose = () => {
    setShowDialog(false);
    onRequestClose();
  };

  const createLotacao = (values) => {
    const {
      codigo,
      descricao,
      sigla,
      endereco,
      cnpj,
      email,
      telefone,
    } = values;
    LotacaoService.createLotacao({
      codigo,
      descricao,
      sigla,
      endereco,
      cnpj,
      email,
      telefone,
    })
      .then((response) => {
        Alert.success("", "Sucesso ao incluir o Lotação");
        handleClose();
      })
      .finally(() => setCarregando(false));
  };

  const updateLotacao = (values) => {
    const {
      idLotacao,
      codigo,
      descricao,
      sigla,
      endereco,
      cnpj,
      email,
      telefone,
    } = values;
    LotacaoService.updateLotacao({
      idLotacao,
      codigo,
      descricao,
      sigla,
      endereco,
      cnpj,
      email,
      telefone,
    })
      .then((response) => {
        Alert.success("", "Sucesso ao atualizar o Lotação");
        handleClose();
      })
      .finally(() => setCarregando(false));
  };

  const renderCarregando = () => {
    return (
      <Box display="flex" justifyContent="center" my={6}>
        <CircularProgress />
      </Box>
    );
  };

  return (
    <Dialog
      title={`Lotação - ${!data ? "Incluir" : "Alterar"}`}
      show={showDialog}
      onRequestClose={onRequestClose}
    >
      <Formik
        initialValues={{
          idLotacao: !data ? undefined : data.idLotacao,
          codigo: !data ? undefined : data.codigo,
          sigla: !data ? undefined : data.sigla,
          descricao: !data ? initValue : data.descricao,
          endereco: !data ? undefined : data.endereco,
          cnpj: !data ? undefined : data.cnpj,
          email: !data ? undefined : data.email,
          telefone: !data ? undefined : data.telefone,
          submit: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          setCarregando(true);
          if (!data) {
            createLotacao(values);
          } else {
            updateLotacao(values);
          }
        }}
      >
        {(formik) => (
          <form noValidate onSubmit={formik.handleSubmit}>
            <Grid container spacing={6}>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="codigo"
                  label="Código"
                  value={formik.values.codigo}
                  fullWidth
                  my={10}
                  required
                  error={Boolean(formik.touched.codigo && formik.errors.codigo)}
                  helperText={formik.touched.codigo && formik.errors.codigo}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="sigla"
                  label="Sigla"
                  value={formik.values.sigla}
                  fullWidth
                  my={10}
                  error={Boolean(formik.touched.sigla && formik.errors.sigla)}
                  helperText={formik.touched.sigla && formik.errors.sigla}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="descricao"
                  label="Descrição"
                  value={formik.values.descricao}
                  fullWidth
                  my={10}
                  required
                  error={Boolean(
                    formik.touched.descricao && formik.errors.descricao
                  )}
                  helperText={
                    formik.touched.descricao && formik.errors.descricao
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="endereco"
                  label="Endereço"
                  value={formik.values.endereco}
                  fullWidth
                  my={10}
                  error={Boolean(
                    formik.touched.endereco && formik.errors.endereco
                  )}
                  helperText={formik.touched.endereco && formik.errors.endereco}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextFieldCNPJ
                  name="cnpj"
                  label="CNPJ"
                  value={formik.values.cnpj}
                  fullWidth
                  my={10}
                  error={Boolean(formik.touched.cnpj && formik.errors.cnpj)}
                  helperText={formik.touched.cnpj && formik.errors.cnpj}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.setFieldValue("cnpj", e, true);
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  name="email"
                  type="email"
                  label="Email"
                  value={formik.values.email}
                  fullWidth
                  my={10}
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextFieldTelefone
                  name="telefone"
                  label="Telefone"
                  value={formik.values.telefone}
                  fullWidth
                  my={10}
                  error={Boolean(
                    formik.touched.telefone && formik.errors.telefone
                  )}
                  helperText={formik.touched.telefone && formik.errors.telefone}
                  onBlur={formik.handleBlur}
                  onChange={(ret) => {
                    formik.setFieldValue("telefone", ret, true);
                  }}
                />
              </Grid>
            </Grid>
            <Box my={2} />
            {carregando ? (
              renderCarregando()
            ) : (
              <Grid container spacing={2}>
                <Grid item>
                  <CancelarButton onClick={handleClose} />
                </Grid>
                <Grid item>
                  {!data ? <CadastrarButton /> : <AlterarButton />}
                </Grid>
              </Grid>
            )}
          </form>
        )}
      </Formik>
    </Dialog>
  );
}

export default DialogLotacao;
